a {
  text-decoration: none;
  color: black;
}
.mainLayout {
  width: 100%;

  /* background-color: red; */
}

header {
  width: 97%;
  max-width: 2000px;

  background-color: rgb(255, 255, 255);

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  padding: 1em 2.5em;
  border-radius: 12px;

  box-shadow: 0px 0px 60px 0px rgb(230, 227, 227);

  position: fixed;
  /* inset: 1em 0 0 0; */
  top: 1em;
  right: 0;
  left: 0;

  z-index: 99999;

  margin-inline: auto;
}

nav {
  width: 47%;

  /* background-color: yellowgreen;
      display: flex;
      flex-direction: row;
  
      align-items: center;
      justify-content: center; */
}

.navList {
  width: 100%;

  list-style: none;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-evenly;

  gap: 0.5em;
}

.navLink {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.8rem, 1.15vw, 1.15rem);
  font-weight: 600;

  letter-spacing: -0.02em;

  padding: 0.5em 0.6em;

  border-radius: 10px;

  cursor: pointer;

  transition: background-color 0.3s;
}

.navLink:hover {
  background-color: #aabdf027;
}

.home {
  width: 27px;

  margin-right: 0.5em;
}

.homeIconAndText {
  display: flex;
  flex-direction: row;

  align-items: center;
}

.homeFeghasUndeline {
  border-bottom: 1.5px solid black;

  padding-bottom: 0.2em;
}

.feghasLogo {
  width: 110px;
  inset: 1/1;
}

.contactBtn {
  border: 0.1px solid #000;

  background: #000;
  color: #fff;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.8rem, 1.05vw, 1.05rem);
  font-weight: 600;

  padding: 0.55em 1.2em;

  border-radius: 60px;

  transition: border-color 0.3s color 0.3s background-color 0.3s;
  cursor: pointer;
}

.contactBtn:hover {
  background-color: #fff;
  color: #000;
  border-color: #dbdbdb;
}

.sr-only {
  display: none;
  visibility: hidden;
  opacity: 0;
}

.academyHero {
  width: 100%;

  margin-inline: auto;
}

.innerAcademySection {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: center;
  justify-content: space-between;

  gap: 1em;

  margin-top: clamp(8rem, 5.556vw + 6.222rem, 14rem);

  padding: 1em clamp(2rem, 7.407vw + -0.37rem, 10rem);

  margin-inline: auto;
}

.heroContent {
  width: 50%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  /* background-color: red; */

  gap: 1.5em;
}

.heroContentHead {
  width: 100%;
}

.acadHeroHead {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.8rem, 5vw, 4.5rem);
  font-weight: 400;

  line-height: 1.15;

  text-transform: capitalize;
}

.mastery {
  font-weight: 700;
}

.bluClr {
  font-weight: 700;

  color: #3772ff;
}

.acadHeroSubText {
  width: min(100%, 650px);
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.85rem, 1.2vw, 1.15rem);
  font-weight: 400;
  line-height: 1.5;

  color: #575757;

  /* background-color: red; */
}

.acadHeroBtns {
  width: 100%;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: flex-start;

  gap: 1.5em;
}

.acadHeroBtn {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.8rem, 1.1vw, 1.05rem);
  font-weight: 500;

  padding: 0.6em 1.5em;

  border-radius: 60px;
  border: none;

  transition: 0.3s;
}

.greenBgClor {
  background-color: #6fae4d;
  color: #fff;
}

.greenBgClor:hover {
  background-color: #8ecc6c;
  cursor: pointer;
}

.noBgClr {
  background-color: #fff;
  border: 1px solid #000;
}

.noBgClr:hover {
  background-color: #7070702a;
  cursor: pointer;
}

.heroRate {
  width: 100%;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: flex-start;

  gap: 5em;
}

.rates {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
}

.rateNum {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.4rem, 3vw, 2.5rem);
  font-weight: 500;
}
.rateStatus {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.8rem, 1vw, 0.9rem);
  font-weight: 500;

  color: #a3a3a3;
}

.acadHeroImage {
  width: 45%;
  max-width: 600px;

  object-fit: cover;
}

.confidence {
  width: 100%;

  margin-inline: auto;
}

.innerConfidence {
  width: 100%;

  display: flex;
  flex-direction: row;

  flex-wrap: wrap;

  align-items: center;
  justify-content: space-around;

  gap: 1em;

  padding: 1em clamp(2rem, 3.704vw + 0.815rem, 6rem);

  margin: clamp(4rem, 3.704vw + 2.815rem, 8rem) 0em;
  margin-inline: auto;

  /* background-color: red; */
}

.conText {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.85rem, 1.1vw, 1rem);
  font-weight: 500;
}

.howItWorks {
  width: 100%;

  margin-bottom: 6em;
  margin-inline: auto;
}

.innerHowItWorks {
  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 1.5em;

  padding: 0em clamp(2rem, 7.407vw + -0.37rem, 10rem);
  margin-inline: auto;
}

.howItWorksTitle {
  width: 12%;
  min-width: 135px;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  gap: 0.5em;

  background-color: #f9fafb;
  /* background-color: red; */
  border: 1px solid #e4e7ec;
  border-radius: 60px;
  padding: 0.4em 1em;
}

.bookIcon {
  width: 16px;

  aspect-ratio: 1/1;
}

.howItWorksText {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.7rem, 0.94vw, 0.95rem);
  font-weight: 500;
}

.howItWorksHead {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.8rem, 3vw, 3rem);
  font-weight: 700;

  color: #939393;
}

.mrkBld {
  color: #000;
}

.processWrap {
  width: 100%;

  /* margin-bottom: 5em; */
}

.process {
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: center;
  justify-content: flex-start;

  gap: 0.5em;

  position: relative;

  /* background-color: red; */

  list-style-position: inside;

  /* padding: 1.2em 1em; */
  /* marker-end: red; */
}

.process ::marker {
  color: #98a2b3;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.7rem, 2vw, 2rem);
  font-weight: 600;
}

/* .process > li {
  display: list-item;
} */

.stepPoint {
  width: 24%;
  min-width: 250px;
  height: max(30vh, 160px);

  background-color: #f2f4f7;

  position: relative;

  padding: 2em 1em 2em 2.8em;

  border-radius: 20px;

  flex: 1;
}

.stepPoint::before {
  content: "0";

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.7rem, 2vw, 2rem);
  font-weight: 600;

  position: absolute;
  left: 0.8em;

  color: #98a2b3;
}

.point {
  display: block;

  position: absolute;
  top: 5em;
  left: 1.6em;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1rem, 1.1vw, 1.1rem);
  font-weight: 600;
}

.subText {
  display: block;

  position: relative;
  top: 4em;
  left: -1em;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.75rem, 0.95vw, 0.95rem);
  font-weight: 400;
}

.joinCall {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1rem, 1.05vw, 1.05rem);
  font-weight: 400;
}

.mrkSmBld {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1rem, 1.05vw, 1.05rem);
  font-weight: 600;
}

.joinNowBtn {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.8rem, 1.1vw, 1.05rem);
  font-weight: 500;

  padding: 0.6em 1.5em;

  border-radius: 60px;
  border: none;

  background-color: #6fae4d;
  color: #fff;

  transition: 0.3s;
}

.joinNowBtn:hover {
  background-color: #8ecc6c;
  cursor: pointer;
}

.popularCourses {
  width: 100%;

  margin-inline: auto;
}

.innerPopularCourses {
  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  align-items: center;

  gap: 1.5em;

  padding: 0em clamp(2rem, 7.407vw + -0.37rem, 10rem);

  margin-bottom: 6em;
}

.courseCat {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1rem, 1.1vw, 1.1rem);
  font-weight: 400;

  text-align: center;

  text-transform: uppercase;

  color: #7fb7cc;
}

.popularCourseTxt {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.4rem, 2vw, 2rem);
  font-weight: 400;

  text-align: center;

  text-transform: capitalize;

  color: #000;

  margin-top: 0.2em;
}

.popularCourseCardWrap {
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: center;
  justify-content: center;

  gap: 1em;

  margin-top: 1em;
}

.popularCourseCard {
  width: 31%;
  min-width: 270px;
  height: 15vw;
  min-height: 180px;

  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: 30%;

  align-items: center;
  justify-content: flex-end;

  border-radius: 30px 30px 0 0;

  padding: 1em 1.5em;

  /* background-color: red; */

  /* background-image: url("./assets/courseWebDevBg.svg"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  position: relative;
}

.popularCourseCard::after {
  content: "";

  width: 100%;
  height: 100%;

  border-radius: 30px 30px 0 0;

  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);

  position: absolute;
  top: 0;
}

.popularCourseCard:hover::after {
  content: "";

  width: 100%;
  height: 100%;

  border-radius: 30px 30px 0 0;

  background: linear-gradient(150deg, rgba(0, 0, 0, 0) 0%, #000 100%);

  position: absolute;
  top: 0;
}

.innerPopularCourse {
  width: 100%;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  z-index: 1;
}

.courseTitle {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1vw, 1rem);
  font-weight: 300;

  color: #fff;
}

.courseGoal {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.7rem, 0.75vw, 0.75rem);
  font-weight: 100;

  color: #fff;
}

.courseCardBtn {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.7rem, 0.8vw, 0.8rem);
  font-weight: 400;

  padding: 0.6em 1.5em;

  border-radius: 60px;
  border: none;

  /* background-color: #3772ff; */
  color: #fff;

  transition: 0.3s;
}

.courseCardBtn:hover {
  background-color: #b8b8b860;
  cursor: pointer;
}

.courseSeeMoreBtn {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.85rem, 0.95vw, 0.95rem);
  font-weight: 400;

  padding: 0.8em 1.5em;

  border-radius: 60px;
  border: none;

  background-color: #6fae4d;
  color: #fff;

  transition: 0.3s;

  margin-top: 1em;
}

.courseSeeMoreBtn:hover {
  background-color: #fff;
  border: 1px solid black;
  color: #000;
  font-weight: 500;
  cursor: pointer;
}

.whatOurStudentSay {
  width: 100%;

  padding: 0em clamp(2rem, 7.407vw + -0.37rem, 10rem);
  margin-inline: auto;
}

.whatOurStudentSayHead {
  /* width: 10%; */
  text-align: center;

  border: 0px solid #e4e7ec;
  border-radius: 0;
  background: none;
  color: #000;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.8rem, 2.5vw, 2.5rem);
  font-weight: 600;
  padding: 0;

  margin-top: 0em;
  margin-bottom: 1em;
}

.whatStudentSayScroller {
  width: 100%;

  margin-inline: auto;

  background-color: #3772ff;

  position: relative;
  top: 0;

  margin-bottom: 5em;
  padding: 4em 0;

  overflow: hidden;
}

/* .innerWhatStudentSayScroller {
  width: calc(340px * 12);

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  align-items: center;
  justify-content: flex-start;

  gap: 1em;

  

  animation: Scrollr 40s linear infinite;
} */

@keyframes ScrollingLefts {
  to {
    left: -340px;
  }
}

.innerWhatStudentSayScroller {
  width: clamp(65.625rem, 31.829vw + 55.44rem, 100rem);
  /* min-width: 1536px; */
  height: 35vh;

  /* display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: flex-start;

  gap: 1em; */

  position: relative;

  /* left: 4em; */
}

.studentCommentContainer {
  width: 340px;
  /* height: 100%; */
  /* height: 280px; */

  background-color: #fff;
  border-radius: 24px;

  padding: 2em 1.5em;

  margin-block: auto;

  position: absolute;
  left: 100%;

  animation: ScrollingLefts 10s linear infinite;
}

.commentCount1 {
  animation-delay: calc(20s / 8 * (8 - 1) * -1);
}

.commentCount2 {
  animation-delay: calc(20s / 8 * (8 - 2) * -2);
}

.commentCount3 {
  animation-delay: calc(20s / 8 * (8 - 3) * -1);
}

.commentCount4 {
  animation-delay: calc(20s / 8 * (8 - 4) * -1);
}

.commentCount5 {
  animation-delay: calc(20s / 8 * (8 - 5) * -1);
}

.commentCount6 {
  animation-delay: calc(20s / 8 * (8 - 6) * -1);
}

.commentCount7 {
  animation-delay: calc(20s / 8 * (8 - 7) * -1);
}

.commentCount8 {
  animation-delay: calc(20s / 8 * (8 - 8) * -1);
}

.commentCount9 {
  animation-delay: calc(20s / 8 * (8 - 9) * -1);
}

.commentCount10 {
  animation-delay: calc(20s / 8 * (8 - 10) * -1);
}

.commentCount11 {
  animation-delay: calc(20s / 8 * (8 - 11) * -1);
}

.commentCount12 {
  animation-delay: calc(20s / 8 * (8 - 12) * -1);
}

.commentCount13 {
  animation-delay: calc(20s / 8 * (8 - 13) * -1);
}

.commentCount14 {
  animation-delay: calc(20s / 8 * (8 - 14) * -1);
}

.commentCount15 {
  animation-delay: calc(20s / 8 * (8 - 15) * -1);
}

/* @keyframes Scrollr {
  0% {
    transform: translate(0);
  }
  100% {
    transform: translate(calc(-390px * 12));
  }
} */

.studentCommentText {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.85rem, 1.1vw, 1.1rem);
  font-weight: 400;

  color: #98a2b3;

  margin-bottom: 2em;
}

.questions {
  width: 100%;

  margin: 10em 0em;
  margin-inline: auto;
}

.innerQuestions {
  width: 100%;

  padding: 0em clamp(2rem, 7.407vw + -0.37rem, 10rem);
  margin-inline: auto;
}

.questionsHead {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.8rem, 2.5vw, 2.5rem);
  font-weight: 700;

  text-align: center;

  margin-top: 2em;
}

.questionsS {
  width: 100%;

  margin: 3em 0em;
  margin-inline: auto;
}

.questionWrap {
  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 1em;

  padding: 2em 2em;

  background-color: #3773ff28;

  border-radius: 10px;

  margin-bottom: 0.8em;

  transition: 0.3s;
}

.questionWrap:hover {
  background-color: #3773ff49;
  cursor: pointer;
}

.questionOne {
  width: 100%;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;
}

.question {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1rem, 1.3vw, 1.3rem);
  font-weight: 500;
}

.answer {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1rem, 1.3vw, 1.3rem);
  font-weight: 400;

  display: none;
}

.show {
  display: block;
}

.questionExpands {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1rem, 1.3vw, 1.3rem);
  font-weight: 500;
}

.becomeExpert {
  width: 100%;

  margin-inline: auto;
}

.innerBecomeExpert {
  width: 100%;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  gap: 1em;
  padding: 0em clamp(2rem, 7.407vw + -0.37rem, 10rem);

  margin: 4em 0 8em 0;
  margin-inline: auto;
}

.becomeExpertTextArea {
  width: 50%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 1.4em;
}

.becomeExpertHeadText {
  width: 60%;
  min-width: 300px;

  /* background-color: red; */
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.4rem, 2.5vw, 2.5rem);
  font-weight: 400;
}

.becomeExpertSubText {
  width: 95%;
  /* min-width: 400px; */
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1.1vw, 1.1rem);
  font-weight: 400;

  line-height: 1.6;
}

.becomeExpertForm {
  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;
  gap: 0.5em;
}

.becomeExpertLabel {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1.1vw, 1.1rem);
  font-weight: 700;

  text-transform: uppercase;
}

.inputAndButtion {
  width: 100%;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: flex-start;

  gap: 1em;
}

.becomeExpertInput {
  width: 60%;

  padding: 1em 2em;

  border-radius: 60px;
  border: 1px solid #000;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.8rem, 1vw, 1rem);
  font-weight: 400;
}

.becomeExpertInput:focus {
  outline-style: none;
}

.becomeExpertBtn {
  padding: 0.8em 2em;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.8rem, 1vw, 1rem);
  font-weight: 400;

  border: none;
  border-radius: 60px;

  background-color: #6fae4d;
  color: #fff;

  transition: background-color 0.5s;
}

.becomeExpertBtn:hover {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;

  cursor: pointer;
}

.becomeExpertImage {
  width: 35%;
}

footer {
  width: 100%;
  /* min-width: 430px; */

  padding: 4em clamp(2rem, 7.407vw + -0.37rem, 10rem);
}

.innerAcadFooter {
  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 2em;
}

.footersListWrap {
  width: 100%;

  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */

  align-items: flex-start;
  justify-content: flex-start;

  gap: 2em;
}

.footerImageContents {
  width: 70%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 1em;
}

.footerSubtext {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.8rem, 1vw, 1rem);
  font-weight: 300;

  color: #979797;
}

.powered {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  gap: 1em;

  border-radius: 10px;

  padding: 1em 2em;

  background-color: #150e0f;
}

@media (max-width: 500px) {
  .powered {
    width: 70vw;
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    gap: 1em;

    border-radius: 10px;

    padding: 1em 2em;

    background-color: #150e0f;
  }
}

.poweredTexttitle {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.7rem, 0.85vw, 0.85rem);
  font-weight: 500;

  text-transform: uppercase;
}
.poweredSubtext {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.74rem, 1vw, 1rem);
  font-weight: 300;

  text-transform: capitalize;

  display: flex;
  flex-direction: row;

  align-items: flex-end;
  justify-content: space-between;
}

.arrowUp {
  width: 20px;
}

.footerHeadLists {
  width: 15%;

  list-style: none;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: flex-start;

  gap: 1em;
}

.footerLinks {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.85rem, 1vw, 1rem);
  font-weight: 300;

  color: #808080;
}

.socialMed {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.85rem, 1vw, 1rem);
  font-weight: 700;

  color: #fff;
}

.copyRight {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.85rem, 1vw, 1rem);
  font-weight: 300;

  color: #808080;

  margin-top: 3em;
}

.aboutHero {
  width: 100%;
  /* min-width: 430px; */

  margin-inline: auto;
}

.innerAboutus {
  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 1.5em;

  margin: min(40vw, 13em) 0em;

  padding: 0em clamp(2rem, 7.407vw + -0.37rem, 10rem);
  margin-inline: auto;
}

.aboutUsHeroHead {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(2.5rem, 4.5vw, 4.5rem);
  font-weight: 400;

  text-align: left;
}

.aboutUsSubtext {
  width: 50%;
  min-width: 350px;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1.1vw, 1.1rem);
  font-weight: 400;

  line-height: 1.6;
}

.aboutUsHeroBtn {
  width: 70%;
  min-width: 400px;

  /* background-color: rebeccapurple; */

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: center;
  justify-content: flex-start;

  gap: 1em;
}

.aboutUsHeroBtn > * {
  width: 17%;
  min-width: 120px;

  /* background-color: red; */
  /* flex: 1; */

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1rem, 1.2vw, 1.2rem);
  font-weight: 500;

  border-radius: 60px;

  padding: 0.9em 1em;

  color: #fff;
}

.orangeBtn {
  background: #f25022;
  border: 9px solid rgba(237, 237, 237, 0.98);
}

.yelloBtn {
  background: #fdca40;
  border: 9px solid rgba(237, 237, 237, 0.98);

  color: #000;
}

.greenBtn {
  width: 23%;
  min-width: 170px;

  font-size: clamp(0.95rem, 1.2vw, 1.2rem);

  background: #6fae4d;
  border: 9px solid rgba(237, 237, 237, 0.98);
}

.blueBtn {
  width: 23%;
  min-width: 170px;

  background: #3772ff;
  border: 9px solid rgba(237, 237, 237, 0.98);
}

.unlockBoundless {
  width: 100%;
  /* min-width: 430px; */

  padding: 0em clamp(2rem, 7.407vw + -0.37rem, 10rem);

  margin-inline: auto;
}

.innerBoundless {
  width: 100%;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  gap: max(7vw, 2em);

  margin: -4em 0em 10em 0em;

  margin-inline: auto;

  background-color: #000;
  color: #fff;

  padding: clamp(3rem, 3.824vw + 1.776rem, 7rem)
    clamp(2rem, 0.956vw + 1.694rem, 3rem);
}

.boundlessImageArea {
  width: 70%;
  /* height: auto; */
  position: relative;

  /* background-color: red; */
  align-self: flex-start;
}

.boundlessImg {
  width: 18vw;
  min-width: 200px;

  position: absolute;
  top: -8vw;
}

.boundlessTextArea {
  width: auto;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 1.5em;
}

.boundlessHeadText {
  width: 90%;
  min-width: 400px;

  /* background-color: red; */
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.8rem, 3.3vw, 3.3rem);
  font-weight: 300;

  line-height: 1.1;
}

.boundlessSubtext {
  width: 85%;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.85rem, 1.05vw, 1.05rem);
  font-weight: 300;

  line-height: 1.5;
}

.boundlessBtn {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.85rem, 1.05vw, 1.05rem);
  font-weight: 400;

  border-radius: 60px;

  padding: 0.45em 1.2em;

  border: none;

  color: #fff;
  background-color: #6fae4d;

  transition: 0.3s;
}

.boundlessBtn:hover {
  background-color: #84ad6d;
  cursor: pointer;
}

.meetFeghas {
  width: 100%;

  margin-inline: auto;
}

.innerMeetFeghas {
  width: 100%;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  gap: clamp(2rem, 2.868vw + 1.082rem, 5rem);

  padding: 0em clamp(2rem, 7.407vw + -0.37rem, 10rem);

  margin: 8em 0;
  margin-inline: auto;
}

.meetFeghasImage {
  width: 90%;

  display: grid;
  place-content: center;
}

.meetFeghasImg {
  width: 80%;
  min-width: 250px;
}

.meetFeghasTextArea {
  width: 60%;
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 1em;
}

.meetFeghasCTA {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1.1vw, 1.1rem);
  font-weight: 400;
}

.meetFeghasOurValue {
  text-transform: uppercase;
}

.meetFeghasExpt {
  text-transform: capitalize;

  font-size: clamp(0.9rem, 2vw, 2rem);

  margin-top: 0.3em;
}

.meetFeghasCTAFocus {
  width: 100%;
}

.meetFeghasCTAFocus {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(3rem, 4.5vw, 4.5rem);
  font-weight: 400;

  line-height: 1.05;
}

.digital {
  /* font-size: clamp(3rem, 4.5vw, 4.5rem); */
  color: #fdca40;
}

.intelligence {
  font-size: clamp(2.8rem, 3.8vw, 3.8rem);
  font-weight: 700;

  color: #6fae4d;
}

.advanced {
  font-size: clamp(3.3rem, 4.4vw, 4.4rem);

  color: #000;
}

.meetFeghasSubtext {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1.05vw, 1.05rem);
  font-weight: 300;
}

.missionStatement {
  width: 100%;
  /* min-width: 430px; */

  margin-inline: auto;
}

.innermissionStatement {
  width: 100%;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  gap: 1em;

  margin: 10em 0em;

  padding: 0em clamp(2rem, 7.407vw + -0.37rem, 10rem);
}

.missionStateTextArea {
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 1.5em;
}

.missionCTA {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1.1vw, 1.1rem);
  font-weight: 500;

  text-transform: uppercase;
}

.missionHeadText {
  width: 70%;
  min-width: 300px;

  /* background-color: red; */

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.5rem, 2.5vw, 2.5rem);
  font-weight: 500;

  line-height: 1.2;
}

.missionSubtext {
  width: 75%;
  min-width: 300px;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1.15vw, 1.15rem);
  font-weight: 400;

  line-height: 1.5;
}

.missionPointWrap {
  width: 55%;
  min-width: 300px;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 0.5em;
}

.missionPoints {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1rem, 1.2vw, 1.2rem);
  font-weight: 700;
}

.missionPointsSubtext {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1.1vw, 1.1rem);
  font-weight: 400;
}

.missionImageAreaWrap {
  width: 45%;
}

.missionImageArea {
  width: 100%;
  min-width: 300px;
}

.whyFeghasHero {
  width: 100%;

  margin-inline: auto;
}

.innerWhyFeghasHero {
  width: 100%;

  margin-top: min(40vw, 13em);
  margin-bottom: 8em;

  padding: 0em clamp(2rem, 7.407vw + -0.37rem, 10rem);

  margin-inline: auto;
}

.whyFeghasHeadText {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(2rem, 4vw, 4rem);
  font-weight: 500;
}

.whyFeghasSubtext {
  width: 50%;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1rem, 1.2vw, 1.2rem);
  font-weight: 400;

  color: #9b9b9b;

  line-height: 1.5;

  margin-top: 0.5em;
}

.WhyFeghasExperience {
  width: 100%;

  margin-inline: auto;
}

.innerWhyFeghasExperience {
  width: 100%;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  gap: 1em;

  padding: 0em clamp(2rem, 7.407vw + -0.37rem, 10rem);
  margin-inline: auto;
}

.whyFeghasExperienceImageArea {
  width: 45%;
}

.whyFeghasImage {
  width: 100%;
}

.whyFeghasExperienceTextArea {
  width: 50%;
}

.whyExperienceHeadText {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.2rem, 2.5vw, 2.5rem);
  font-weight: 500;
}

.whyFeghasExperienceSubText {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1rem, 1.15vw, 1.15rem);
  font-weight: 400;

  line-height: 1.5;

  margin-top: 1.2em;
}

.whyFeghasExperienceDetails {
  width: 100%;
}

.whyFegExpName {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1rem, 1.1vw, 1.1rem);
  font-weight: 400;

  margin-top: 1.5em;
}

.whyFegExpLocation {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1rem, 1.1vw, 1.1rem);
  font-weight: 400;
}

.whyFegExpStat {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1rem, 1.1vw, 1.1rem);
  font-weight: 400;
}

.whyFegExpDpAndStatus {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: flex-start;

  gap: 1.5em;

  margin-top: 1.5em;
}

.whyFegExpDpAndStatusImg {
  width: 50px;
  aspect-ratio: 1/1;

  object-fit: cover;

  border-radius: 100%;
}

.whyFegExpDpAndStatusName {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1rem, 1.1vw, 1.1rem);
  font-weight: 700;
}

.whyFegExpDpAndStatusDate {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.85rem, 0.9vw, 0.9rem);
  font-weight: 400;

  margin-top: 0.2em;
}

.weHelped {
  width: 100%;

  margin-inline: auto;
}

.innerWeHelped {
  width: 100%;

  margin-top: 8em;
  margin-bottom: 8em;

  padding: 0em clamp(2rem, 7.407vw + -0.37rem, 10rem);
}

.weHelpedHeadText {
  width: 65%;
  min-width: 400px;
  /* background-color: red; */
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.6rem, 3vw, 3rem);
  font-weight: 400;
}

.weHelpedRates {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: flex-start;

  gap: 1.5em;

  margin-top: 2.5em;
}

.weHelpedRate:nth-child(1) {
  background-color: #1362f3;
}

.weHelpedRate:nth-child(2) {
  background-color: #20a84e;
}

.weHelpedRate:nth-child(3) {
  background-color: #f7931e;
}

.weHelpedRate {
  font-family: "Space Grotesk", sans-serif;

  color: #fff;

  padding: 1em 1.5em;

  border-radius: 20px;
}

.weHelpedRateLevel {
  font-size: clamp(1.5rem, 2.6vw, 2.6rem);
  font-weight: 400;

  text-align: center;
}

.weHelpedRateLevelSubtext {
  font-size: clamp(0.8rem, 0.9vw, 0.9rem);
  font-weight: 400;

  text-align: center;
}

.guaranteed {
  width: 100%;

  margin-inline: auto;
}

.innerGuaranteed {
  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 3em;

  margin-bottom: 8em;

  padding: 0em clamp(2rem, 7.407vw + -0.37rem, 10rem);
}

.guaranteedHeads {
  width: 100%;
}

.guaranteedHeadText {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.5rem, 2.3vw, 2.3rem);
  font-weight: 500;
}

.guaranteedSubText {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1rem, 1.1vw, 1.1rem);
  font-weight: 400;

  margin-top: 0.5em;
}
.guaranteedPoints {
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: center;
  justify-content: space-around;

  gap: 1em;
}

.guaranteedPoint {
  width: 25%;
  min-width: 200px;
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 1em;

  padding: 1.5em;

  background-color: #fdfdfd;

  border-radius: 10px;
}

.guaranteedPoints > * {
  /* flex: 1; */
  flex-basis: 25%;
}

.guaranteedPointImg {
  width: 40px;

  padding: 0.5em;

  border-radius: 100%;

  background-color: rgba(19, 98, 243, 0.2);
}

.guaranteedPointHead {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1rem, 1.2vw, 1.2rem);
  font-weight: 600;
}

.guaranteedPointSubtext {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1vw, 1rem);
  font-weight: 400;
}

.coursesHero {
  width: 100%;

  margin-inline: auto;
}

.innerCoursesHero {
  width: 100%;

  margin-top: min(40vw, 13em);

  padding: 0em clamp(2rem, 7.407vw + -0.37rem, 10rem);
  margin-inline: auto;
}

.coursesHeadText {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(2rem, 4.2vw, 4.2rem);
  font-weight: 400;
}

.coursesSubtext {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1.1vw, 1.1rem);
  font-weight: 400;

  color: #9a9a9a;
}

.coursesWrap {
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: center;
  justify-content: space-between;

  gap: 2em;

  margin: 3em 0em 8em 0;
}

.coursesWrap > * {
  flex: 1;
  flex-basis: 30.5%;
}

.coursesCardWrap {
  /* width: 50%; */
  height: max(17vw, 200px);

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: flex-end;

  padding: 2em;

  /* background-color: red; */

  border-radius: 30px 30px 0 0;

  /* background-image: url("./assets/courseWebDevBg.svg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  position: relative;
}

.coursesCardWrap:nth-child(1) {
  background-image: url("./assets/courseWebDevBg.svg");
}

.coursesCardWrap::after {
  content: " ";

  width: 100%;
  height: 100%;

  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);

  position: absolute;
  top: 0;

  border-radius: 30px 30px 0 0;
}

.coursesNameAndTimeLocal {
  width: 100%;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  z-index: 2;
}

.coursesTitle {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.85rem, 1.2vw, 1.2rem);
  font-weight: 500;
  color: #fff;
}

.handsOn {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.7rem, 0.9vw, 0.9rem);
  font-weight: 400;
  color: #fff;
}

.coursesBtn {
  background: #3772ff;
  border: none;
  border-radius: 60px;

  padding: 0.7em 1em;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.7rem, 0.9vw, 0.9rem);
  font-weight: 400;

  color: #fff;

  transition: 0.3s;
}

.coursesBtn:hover {
  background: #3773ff80;
  cursor: pointer;
}

.register {
  width: 100%;
  height: calc(100dvh - 0em);

  margin-inline: auto;

  /* background-color: red; */
}

.innerRegister {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  gap: 1em;

  padding: 1em 0.8em 1em clamp(2rem, 7.407vw + -0.37rem, 10rem);
}

.registerForm {
  width: 35%;
  /* min-width: 450px; */
  height: 100%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 1em;

  /* background-color: red; */
}

.backToWeb {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  gap: 1em;

  padding: 0.5em 1em;

  /* background-color: #f8f8f8; */
  border-radius: 60px;
  transition: 0.5s;
}

.backToWeb:hover {
  background-color: #ececec;
  cursor: pointer;
}

.backToWebText {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.8rem, 1vw, 1rem);
  font-weight: 500;
}

.arrowLeft {
  width: 28px;
}

.inputWrap:nth-child(2) {
  margin-top: 2em;
}

.inputWrap {
  width: 100%;

  /* background-color: red; */

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;
  gap: 0.5em;
}

.regLabel {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.85rem, 1vw, 1rem);
  font-weight: 500;
}

.registerInput {
  width: 80%;
  min-width: 300px;

  padding: 1.2em;
  border: 1px solid rgba(18, 17, 39, 0.12);
  border-radius: 7px;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.85rem, 0.95vw, 0.95rem);
  font-weight: 400;
}

.regSelect {
  position: relative;

  /* background-color: red; */
}

/* .registerInput::after {
  content: " ";

  width: 20px;
  height: 100%;

  position: absolute;
  top: 0;
  right: 0;

  background-color: rebeccapurple;

} */

.arrowExpand {
  width: 3rem;
  height: 40%;

  position: absolute;
  top: 2.65em;
  right: 6.6em;

  background-color: #fff;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
}

.arrowDown {
  width: 15px;
}

.registerBtn {
  padding: 0.8em 2em;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1.1vw, 1.1rem);
  font-weight: 500;

  border-radius: 60px;
  border: 12px solid #ffffffd7;

  background-color: #1362f3;
  color: #fff;

  transition: 0.5s;
}

.registerBtn:hover {
  background-color: #fdca40;
  color: #000;
  cursor: pointer;
}

.registerImage {
  width: 60%;
  height: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  align-items: center;
  justify-content: flex-start;

  /* gap: 2em; */

  overflow-x: hidden;

  position: relative;

  border-radius: 20px;
}

.registerImage::after {
  content: "";
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;

  background: linear-gradient(180deg, rgba(97, 97, 97, 0.1) 0%, #3772ff 100%);
}

.sliderText {
  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  position: absolute;
  bottom: 120px;
  left: 8%;

  z-index: 1;
}

.sliderTextHead {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(4rem, 5vw, 5rem);
  font-weight: 400;

  color: #fff;

  line-height: 1;
}

.sliderTextSubText {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.5rem, 2vw, 2rem);
  font-weight: 400;

  color: #fff;

  margin-top: 0.6em;
}

.dispBlck {
  display: block;
}

.indicator {
  width: 100%;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  gap: 0.5em;

  position: absolute;
  bottom: 70px;
  right: 0;

  z-index: 1;
}

.dash {
  width: 30px;
  height: 3.5px;

  background-color: #fff;
  opacity: 0.4;

  cursor: pointer;

  transition: 750ms all ease-in-out;
}

.imageSlideContainer {
  width: 100%;
  height: 100%;

  flex-shrink: 0;

  border-radius: 20px;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  transition: 750ms all ease-in-out;
}

.formFeedBack {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;

  backdrop-filter: blur(5px);
  background-color: #00000088;

  z-index: 2;
}

.innerFormFeedBack {
  width: 30%;
  min-width: 350px;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  gap: 2em;

  margin-inline: auto;

  background-color: #fff;

  padding: 4em 1em;

  position: relative;

  border-radius: 30px;
}

.closeFeedBtn {
  width: 30px;
  height: 30px;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1vw, 1rem);
  font-weight: 500;

  text-align: center;

  position: absolute;
  top: 25px;
  right: 35px;

  display: grid;
  place-content: center;

  /* padding: 0.5em 0.5em; */

  border: 0.5px solid grey;
  border-radius: 100%;

  transition: all 0.3s;
}

.closeFeedBtn:hover {
  background-color: #d6d6d6;
  cursor: pointer;
}

.displayNoneFB {
  display: none;
}

.successIcon {
  width: 60px;

  padding: 0.8em;

  border-radius: 100%;

  object-fit: cover;
  /* background-color: #2cc36b; */
  position: relative;
}

.failIcon {
  width: 60px;

  padding: 0.8em;

  border-radius: 100%;

  object-fit: cover;
  background-color: red;
  position: relative;
}

.formFeedBackText {
  width: 100%;
}

.formFeedBackHead {
  width: 80%;
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.2rem, 1.3vw, 1.3rem);
  font-weight: 600;

  text-align: center;

  margin-inline: auto;
}

.formFeedBackSubText {
  width: 85%;
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1rem, 1vw, 1rem);
  font-weight: 400;

  margin-top: 0.8em;

  margin-inline: auto;

  text-align: center;
}

.backToHomeFeedback {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1rem, 1.05vw, 1.05rem);
  font-weight: 400;

  padding: 1em 1.5em;

  background: #3772ff;
  border-radius: 60px;

  border: 15px solid #ffffffe7;

  color: #fff;

  transition: all 0.3s;
}

.backToHomeFeedback:hover {
  background: #fdca40;
  color: #000;
}

@media (max-width: 877px) {
  .innerRegister {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    gap: 1em;

    padding: 1em 0.8em 1em 1em;
  }
}

@media (max-width: 877px) {
  .innerRegister {
    flex-direction: column;

    padding: 1em 2em 1em 2em;
  }

  .registerForm {
    width: 100%;
    /* min-width: 450px; */
    height: 100%;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    gap: 1em;

    /* background-color: red; */
  }

  .registerInput {
    width: 100%;
    min-width: 300px;

    padding: 1.2em;
    border: 1px solid rgba(18, 17, 39, 0.12);
    border-radius: 7px;

    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.85rem, 0.95vw, 0.95rem);
    font-weight: 400;
  }

  .inputWrap {
    width: 90%;

    /* background-color: red; */

    display: flex;
    flex-direction: column;

    align-items: flex-start;
    justify-content: center;
    gap: 0.5em;

    margin-inline: auto;
  }

  .registerImage {
    display: none;
  }
}

@media (max-width: 740px) {
  .coursesCardWrap {
    padding: 1em;
  }
}

@media (max-width: 610px) {
  .coursesWrap > * {
    flex: 1;
    flex-basis: 100%;
  }
  .coursesCardWrap {
    padding: 1em;
  }
}

@media (max-width: 1200px) {
  .whyFeghasSubtext {
    width: 90%;
  }
}

@media (max-width: 680px) {
  .whyFeghasSubtext {
    width: 100%;
  }

  .innerWhyFeghasExperience {
    width: 100%;

    flex-direction: column;

    align-items: center;

    gap: 2em;
  }

  .whyFeghasExperienceImageArea {
    width: 100%;
  }

  .whyFeghasImage {
    width: 100%;
  }

  .whyFeghasExperienceTextArea {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .guaranteedPoints > * {
    flex-basis: 100%;
  }
}

@media (max-width: 500px) {
  .aboutUsHeroBtn {
    width: 100%;
    min-width: 0px;
  }

  .aboutUsHeroBtn > * {
    width: 17%;
    min-width: 120px;

    /* background-color: red; */
    /* flex: 1; */

    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1rem, 1.2vw, 1.2rem);
    font-weight: 500;

    border-radius: 60px;

    padding: 0.7em 1em;

    color: #fff;
  }

  .orangeBtn {
    background: #f25022;
    border: 7px solid rgba(237, 237, 237, 0.98);
  }

  .yelloBtn {
    background: #fdca40;
    border: 7px solid rgba(237, 237, 237, 0.98);

    color: #000;
  }

  .greenBtn {
    width: 20%;
    min-width: 145px;

    font-size: clamp(0.9rem, 1.2vw, 1.2rem);

    background: #6fae4d;
    border: 7px solid rgba(237, 237, 237, 0.98);
  }

  .blueBtn {
    width: 23%;
    min-width: 145px;
    font-size: clamp(0.9rem, 1.2vw, 1.2rem);
    background: #3772ff;
    border: 7px solid rgba(237, 237, 237, 0.98);
  }

  .weHelpedRates {
    width: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;

    align-items: center;
    justify-content: flex-start;

    gap: 0.5em;

    margin-top: 2.5em;
  }

  .weHelpedHeadText {
    width: 100%;
    min-width: 0px;
    /* background-color: red; */
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.6rem, 3vw, 3rem);
    font-weight: 400;
  }
}

@media (max-width: 750px) {
  .innerMeetFeghas {
    width: 100%;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
  }

  .meetFeghasImage {
    width: 90%;
  }

  .meetFeghasImg {
    width: 85%;
    min-width: 250px;
  }

  .meetFeghasTextArea {
    width: 100%;
  }

  .innermissionStatement {
    width: 100%;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    gap: 2em;

    margin: 10em 0em;

    padding: 0em clamp(2rem, 7.407vw + -0.37rem, 10rem);
  }

  .missionStateTextArea {
    width: 100%;
    display: flex;
    flex-direction: column;

    align-items: flex-start;
    justify-content: center;

    gap: 1.5em;
  }

  .missionCTA {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.9rem, 1.1vw, 1.1rem);
    font-weight: 500;

    text-transform: uppercase;
  }

  .missionHeadText {
    width: 100%;
    min-width: 0px;

    /* background-color: red; */

    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.5rem, 2.5vw, 2.5rem);
    font-weight: 500;

    line-height: 1.2;
  }

  .missionSubtext {
    width: 85%;
    min-width: 0px;

    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.9rem, 1.15vw, 1.15rem);
    font-weight: 400;

    line-height: 1.5;
  }

  .missionPointWrap {
    width: 85%;
    min-width: 0px;

    display: flex;
    flex-direction: column;

    align-items: flex-start;
    justify-content: center;

    gap: 0.5em;
  }

  .missionImageAreaWrap {
    width: 100%;
  }

  .missionImageArea {
    width: 90%;
    min-width: 300px;
  }
}

@media (max-width: 1305px) {
  .boundlessImg {
    width: 18vw;
    min-width: clamp(14rem, 1.912vw + 13.388rem, 16rem);

    position: absolute;
    top: -9vw;
  }
}

@media (max-width: 998px) {
  .boundlessImg {
    width: 18vw;
    min-width: clamp(10rem, 2.868vw + 9.082rem, 13rem);

    position: absolute;
    top: -5.5vw;
  }

  .boundlessHeadText {
    width: 90%;
    min-width: 0px;
    margin-inline-start: auto;
  }

  .boundlessSubtext {
    width: 90%;
    min-width: 0px;

    margin-inline-start: auto;

    /* background-color: red; */
  }
}

@media (max-width: 815px) {
  .boundlessImageArea {
    display: none;
  }

  .boundlessHeadText {
    width: 100%;
    min-width: 0px;
    margin-inline-start: auto;
  }

  .boundlessSubtext {
    width: 100%;
    min-width: 0px;

    margin-inline-start: auto;

    /* background-color: red; */
  }
}

@media (max-width: 820px) {
  .heroContent {
    width: 100%;

    display: flex;
    flex-direction: column;

    align-items: flex-start;
    justify-content: center;

    /* background-color: red; */

    gap: 1.5em;
  }

  .innerAcademySection {
    width: 100%;
    flex-direction: column;

    gap: 3em;
  }

  .acadHeroSubText {
    width: 100%;
    font-size: clamp(1rem, 1.2vw, 1.15rem);
  }

  .acadHeroBtn {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1rem, 1.1vw, 1.05rem);
    font-weight: 500;

    padding: 0.6em 1.5em;

    border-radius: 60px;
    border: none;

    transition: 0.3s;
  }
  .noBgClr {
    background-color: #fff;
    border: 1px solid #000;
  }

  .acadHeroImage {
    width: 100%;
    max-width: 600px;

    object-fit: cover;
  }
}

@media (max-width: 747px) {
  .innerBecomeExpert {
    flex-direction: column;

    gap: 4em;
  }

  .becomeExpertTextArea {
    width: 100%;
  }

  .becomeExpertHeadText {
    width: 60%;
    min-width: 300px;

    /* background-color: red; */
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.8rem, 2.5vw, 2.5rem);
    font-weight: 500;
  }

  .becomeExpertSubText {
    width: 100%;
    /* min-width: 400px; */
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1rem, 1.1vw, 1.1rem);
    font-weight: 400;

    line-height: 1.6;
  }

  .becomeExpertImage {
    width: 80%;
  }

  .footersListWrap {
    width: 100%;

    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */

    align-items: flex-start;
    justify-content: flex-start;

    gap: 2em;
  }

  .footerHeadLists {
    width: 100%;

    gap: 1em;
  }
}

@media (max-width: 1440px) {
  nav {
    width: 50%;

    /* background-color: yellowgreen;
          display: flex;
          flex-direction: row;
      
          align-items: center;
          justify-content: center; */
  }

  .navLink {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1rem, 1.25vw, 1.25rem);
  }

  .contactBtn {
    font-size: clamp(1rem, 1.05vw, 1.05rem);
  }
}

@media (max-width: 1280px) {
  nav {
    width: 58%;

    /* background-color: yellowgreen;
          display: flex;
          flex-direction: row;
      
          align-items: center;
          justify-content: center; */
  }

  .navLink {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.05rem, 1.25vw, 1.25rem);
  }

  .contactBtn {
    font-size: clamp(1rem, 1.05vw, 1.05rem);
  }
}

@media (max-width: 1176px) {
  nav {
    width: 55%;

    /* background-color: yellowgreen;
          display: flex;
          flex-direction: row;
      
          align-items: center;
          justify-content: center; */
  }

  .navLink {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.8rem, 1.25vw, 1.25rem);
  }

  .feghasLogo {
    width: 100px;
    inset: 1/1;
  }

  .contactBtn {
    font-size: clamp(0.9rem, 1.05vw, 1.05rem);
  }
}

@media (max-width: 1145px) {
  nav {
    width: 52%;

    /* background-color: yellowgreen;
          display: flex;
          flex-direction: row;
      
          align-items: center;
          justify-content: center; */
  }

  .navLink {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.8rem, 1.25vw, 1.25rem);
  }

  .contactBtn {
    font-size: clamp(0.8rem, 1.05vw, 1.05rem);
  }
}

@media (max-width: 1114px) {
  nav {
    width: 56%;

    /* background-color: yellowgreen;
          display: flex;
          flex-direction: row;
      
          align-items: center;
          justify-content: center; */
  }

  .navLink {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.8rem, 1.25vw, 1.25rem);
  }

  .contactBtn {
    font-size: clamp(0.8rem, 1.05vw, 1.05rem);
  }
}

@media (max-width: 946px) {
  .responsiveNav {
    display: none;
  }

  .sr-only {
    display: block;
    visibility: visible;
    opacity: 1;

    cursor: pointer;
  }

  .menu {
    width: 27px;

    z-index: 999;
    margin-left: 1em;
  }

  nav {
    width: 100%;
    /* height: 100%; */

    position: fixed;
    inset: 0 0 0 20%;
    background-color: rgb(255, 255, 255);

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: flex-start;

    padding: min(30vh, 10rem) 2rem;

    box-shadow: 0px 0px 40px 0px rgb(230, 227, 227);

    background: hsl(0 0% 100% / 0.1);

    backdrop-filter: blur(3em);
    z-index: 100;
  }

  .navList {
    width: 100%;

    list-style: none;

    display: flex;
    flex-direction: column;

    align-items: flex-start;
    justify-content: space-evenly;

    gap: 0.5em;
  }

  .navLink {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.2rem, 1.15vw, 1.15rem);
    font-weight: 600;

    letter-spacing: -0em;

    padding: 0.5em 0.6em;

    border-radius: 10px;

    cursor: pointer;

    transition: background-color 0.3s;
  }

  .navLink:hover {
    background-color: #aabdf027;
  }

  .home {
    width: 27px;

    margin-right: 0.5em;
  }

  .homeIconAndText {
    display: flex;
    flex-direction: row;

    align-items: center;
  }

  .homeFeghasUndeline {
    border-bottom: 1.5px solid black;

    padding-bottom: 0.2em;
  }

  .feghasLogo {
    width: 110px;
    inset: 1/1;
  }

  .contactBtn {
    z-index: 99;
    position: relative;
    inset: 0 0 0 0;
    margin-inline-start: auto;
  }
}

@media (max-width: 450px) {
  nav {
    padding: min(30vh, 10rem) 1rem;
  }
}
