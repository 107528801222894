a {
  text-decoration: none;
  color: black;
}

header {
  width: 97%;
  max-width: 2000px;

  background-color: rgb(255, 255, 255);
  margin-inline: auto;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  padding: 1em 2.5em;
  border-radius: 12px;

  box-shadow: 0px 0px 60px 0px rgb(230, 227, 227);

  position: fixed;
  /* inset: 1em 0 0 0; */
  top: 1em;
  right: 0;
  left: 0;

  z-index: 99999;
}

.headerWrapper {
  width: 77%;
  /* max-width: 2000px; */

  /* background-color: rgb(241, 0, 0); */

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;
}

nav {
  width: 50%;

  /* background-color: yellowgreen;
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center; */
}

.navB {
  width: 73%;

  /* background-color: #8b5050; */
}

.navList {
  width: 100%;

  list-style: none;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-evenly;

  gap: 0.5em;
}

.navLink {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.8rem, 1.15vw, 1.15rem);
  font-weight: 600;

  letter-spacing: -0.02em;

  padding: 0.5em 0.6em;

  border-radius: 10px;

  cursor: pointer;

  transition: background-color 0.3s;
}

.navLink:hover {
  background-color: #aabdf027;
}

.home {
  width: 27px;

  margin-right: 0.5em;
}

.homeIconAndText {
  display: flex;
  flex-direction: row;

  align-items: center;
}

.homeFeghasUndeline {
  border-bottom: 1.5px solid black;

  padding-bottom: 0.2em;
}

.feghasLogo {
  width: 110px;
  inset: 1/1;
}

.contactBtnn {
  border: 0.1px solid #000;

  background: #000;
  color: #fff;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.8rem, 1.05vw, 1.05rem);
  font-weight: 600;

  padding: 0.55em 1.2em;

  border-radius: 12px;

  transition: border-color 0.3s color 0.3s background-color 0.3s;
  cursor: pointer;
}

.contactBtn:hover {
  background-color: #fff;
  color: #000;
  border-color: #dbdbdb;
}

.sr-only {
  display: none;
  visibility: hidden;
  opacity: 0;
}

.heroSection {
  width: 100%;

  margin: 0 auto;

  position: relative;
  top: 7em;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
}

@media (max-width: 800px) {
  .heroSection {
    width: 100%;

    margin: 0 auto;

    position: relative;
    top: 4em;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.innerSection {
  width: 100%;

  margin: 0 auto;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  gap: 1.8em;
}

.heroTextAndHeroIcon {
  width: 100%;

  /* background-color: red; */

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  gap: 1em;

  position: relative;

  /* margin-bottom: 3.5em; */

  margin-top: 2em;
}

.heroText {
  width: 75%;
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(2.5rem, 2.8vw, 2.8rem);
  font-weight: 300;

  text-align: center;

  line-height: 1;

  margin-inline: auto;
}

.herotextWrap {
  width: 100%;

  display: flex;
  flex-direction: column;

  margin-top: 2.5em;
}

.redClr {
  color: #df2935;
  font-weight: 700;
}

.orangeClr {
  color: #fdca40;
  font-weight: 700;
}

.blueClr {
  color: #7691ff;
  font-weight: 700;
}

.iconWrap {
  width: 100%;

  /* background-color: red; */

  /* position: absolute; */

  overflow: hidden;
}

.innerIconWrap {
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 1em;

  /* background-color: red; */

  position: relative;
  left: 4em;
  /* bottom: -3.7em;  */
}

.firstIconWrap {
  width: 32%;
  background-color: #3772ff;

  border-radius: 10px;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  padding: 0.35em;

  gap: 0.3em;
}

.iconSize {
  width: 29px;
  inset: 1/1;
}

.rectangle {
  width: 56px;
  height: 32px;

  background-color: #fff;

  border-radius: 8px;
}

.secondIconWrap {
  width: 60%;
  border: 1px solid #3772ff;
  border-right: 1px solid red;

  /* border-radius: 10px; */

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: flex-start;

  padding: 0.4em 1em 0.35em 1em;

  gap: 0.9em;

  position: relative;
}

.secondIconWrap::before {
  content: " ";
  width: 4px;
  height: 4px;

  border: 1px solid #3772ff;
  background-color: #fff;

  position: absolute;
  top: -0.15em;
  left: -0.25em;
}

.secondIconWrap::after {
  content: " ";
  width: 4px;
  height: 4px;

  border: 1px solid #3772ff;
  background-color: #fff;

  position: absolute;
  bottom: -0.15em;
  left: -0.25em;
}

.codeIcon {
  width: 50px;
}

.iconSizeCircle {
  width: 30px;
}

.heroSubtext {
  width: 85%;
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.8rem, 1vw, 1rem);
  font-weight: 400;

  text-align: center;
}

.heroBtns {
  width: 90%;

  margin-inline: auto;

  background-color: #3772ff1a;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  gap: 0.1em;

  border-radius: 91.1px;

  padding: 0.6em 0.8em;

  margin-bottom: 8.5em;
}

.heroBtn {
  padding: 0.9em 0.8em;

  border-radius: 39px;
  border: none;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.77rem, 1vw, 1rem);
  font-weight: 400;

  background-color: #3772ff;

  /* line-height: 0; */
  transition: background-color 0.3s border-color 0.3s;
}

.whiteClr {
  color: #fff;
}

.whiteClr:hover {
  background-color: #5f8bf3;
  cursor: pointer;
}

.arrowIcon {
  width: 40px;
}

.blackClr {
  color: #000;
  border: 0.5px solid #818181;

  background: none;
}

.blackClr:hover {
  border: 0.5px solid #5f8bf3;
  cursor: pointer;
}

.support {
  width: 90%;
  height: 50px;

  margin-top: 3em;
  margin-bottom: 3em;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  /* background-color: red; */

  margin-inline: auto;

  position: relative;

  /* border: 1px solid #000; */

  overflow: hidden;
}

.wrapper {
  width: 100%;
  height: 100%;

  position: relative;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  /* background-color: purple; */
}

.logoSize {
  width: min(16vw, 90px);
  aspect-ratio: 1/1;
}

@keyframes scrollLeft {
  to {
    left: -200px;
  }
}

.logo {
  height: 100%;
  position: absolute;
  top: 0em;
  left: 100%;
  animation-name: scrollLeft;
  animation-duration: 15s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  /* background-color: red; */

  margin: 0em 0em;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-self: center;

  /* gap: 1em; */
}

.logo1 {
  animation-delay: calc(15s / 6 * (6 - 1) * -1);
}

.logo1 > img {
  margin-top: 0.3em;
}

.logo2 {
  animation-delay: calc(15s / 6 * (6 - 2) * -1);
}

.logo3 {
  animation-delay: calc(15s / 6 * (6 - 3) * -1);
}

.logo4 {
  animation-delay: calc(15s / 6 * (6 - 4) * -1);
}

.logo5 {
  animation-delay: calc(15s / 6 * (6 - 5) * -1);
}

.logo6 {
  animation-delay: calc(15s / 6 * (6 - 6) * -1);
}

.aboutSection {
  width: 100%;

  /* margin: 0 auto; */
  margin-bottom: 4em;
}

.innerAboutSection {
  width: 85%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  /* background-color: red; */

  margin-inline: auto;

  gap: 1em;
}

.aboutText {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1.1vw, 1.1rem);
  font-weight: 500;

  background-color: #3772ff1a;

  padding: 0.8em 1.5em;

  border-radius: 70px;

  color: #3772ff;
}

.aboutHead {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.55rem, 1.8vw, 1.8rem);
  font-weight: 500;
}

.aboutSubtext {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.8rem, 1.2vw, 1.2rem);
  font-weight: 400;
}

.weOfferSection {
  width: 100%;

  margin: 0 auto;

  background-color: #121212;
}

.innerWeOfferSection {
  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  gap: 2em;

  padding: 2em 1em;

  background-color: #121212;
}

.h1AndLearnBtnWrap {
  width: 90%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 2em;

  margin-inline: auto;
}

.weOfferText {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.55rem, 1.6vw, 1.6rem);
  font-weight: 500;

  color: #fff;
}

.blueColor {
  color: #3772ff;
}

.redColor {
  color: #df2935;
}

.orangeColor {
  color: #fdca40;
}

.learnMore {
  /* width: 50%; */

  background-color: #3772ff;

  border-radius: 67px;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  padding: 0.55em;
}

.learnMoreBtn {
  background: #fff;

  border: none;
  border-radius: 67px;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1vw, 1rem);
  font-weight: 500;

  padding: 0.8em 0.9em;

  color: #3772ff;
}

.arrowDiagonal {
  width: 40px;
}

.offerDisplayWrap {
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: center;
  justify-content: flex-start;

  gap: 2em;
}

.offerDisplay {
  width: 70%;
  max-width: 330px;
  aspect-ratio: 1/1;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  background-color: #181818;
  color: #fff;

  gap: 1em;

  padding: 1em 1em;

  border-radius: 14px;
}

.offerDisplayImage {
  width: 100%;
  max-width: 230px;
  aspect-ratio: 1/1;

  background-color: #202020;
  padding: 1em 1em;

  /* margin-inline: auto; */

  border-radius: 14px;
}

.offerText {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1vw, 1rem);
  font-weight: 300;

  background-color: #202020;
  padding: 1em 1.5em;
  border-radius: 40px;

  margin-inline-end: auto;

  /* position: relative;
    left: 1.5em; */
}

.whyFeghas {
  width: 100%;

  margin-inline: auto;

  /* background-color: #818181; */
}

.innerWhyFegas {
  width: 95%;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  margin-inline: auto;
}

.whyFeghasCall {
  width: 90%;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  gap: 1.5em;

  margin: 4em 0em;
}

.whyFegWrapper {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  gap: 0.3em;

  background-color: #3772ff1a;

  padding: 1.2em;

  border-radius: 60px;
}

.whyFeghasIcon {
  width: 16px;
}

.whyFeghasCallText {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1vw, 1rem);
  font-weight: 600;

  color: #3772ff;
}

.HeadText {
  /* width: 50%; */
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.7rem, 2vw, 2rem);
  font-weight: 800;

  text-align: center;

  line-height: 1.2;

  /* background-color: red; */
}

.digitalSolution {
  width: 100%;

  margin-inline: auto;

  /* background-color: red; */

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  gap: 1em;
}

.digitalSolutionWrap {
  width: 95%;

  /* margin-top: 4em; */

  border-radius: 15px;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  gap: 1em;

  padding: 1.5em 1.5em 0em 1.5em;

  background-color: #f1f1f1;

  overflow: hidden;

  position: relative;

  z-index: 1;
}

.digitalSolutionImageAndTextWrap {
  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 1em;
}

.digitalSolutionImageAndTextWrap2 {
  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 1em;
}

.digitalSolutionWrap::after {
  content: "";

  width: 100%;
  height: 200%;

  position: absolute;
  left: 0;
  bottom: -25em;

  /* background-image: linear-gradient(
    50deg,
    hsl(256deg 93% 61%) 0%,
    hsl(256deg 74% 68%) 2%,
    hsl(256deg 55% 75%) 10%,
    hsl(257deg 37% 81%) 24%,
    hsl(255deg 19% 88%) 46%,
    hsl(0deg 0% 95%) 90%
  ); */

  background: linear-gradient(
    209deg,
    transparent 0%,
    rgba(80, 0, 213, 0.004) 16%,
    rgba(5, 0, 252, 0.069) 51%,
    #794dc2 100%
  );

  z-index: -1;
}

.digitalSolutionImg {
  width: 50px;

  transform: rotate(-20deg);
}

.digitalSolutionHead {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.8rem, 2vw, 2rem);
  font-weight: 800;
}

.digitalSolutionSubText {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1.1vw, 1.1rem);
  font-weight: 500;

  color: #475467;

  line-height: 1.4;
}

.digitalInnerFlowThrough {
  width: 100%;
  /* min-width: 320px; */
  height: 25vh;
  min-height: 270px;

  background-color: #fff;

  border-radius: 15px;
  border: 5px solid #e4e7ec;

  position: relative;
  bottom: -2em;

  overflow: hidden;
}

.imgText {
  width: 700px;
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.5rem, 2vw, 2rem);
  font-weight: 100;

  padding: 0.4em 0.6em;

  background-color: #3772ff;
  border: 20px solid #3773ff38;

  border-radius: 60px;
  border: 5px solid #e4e7ec;

  position: absolute;
  top: 1.5em;
  left: 2em;

  color: #fff;
}

.digitalSolutionWrap2 {
  width: 95%;

  /* margin-top: 0em; */

  border-radius: 15px;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 1em;

  padding: 0.5em 1.5em 2em 1.5em;

  background-color: #f1f1f1;

  overflow: hidden;

  position: relative;

  z-index: 1;
}

.digitalSolutionWrap2::after {
  content: "";

  width: 100%;
  height: 200%;

  position: absolute;
  left: 0;
  bottom: -25em;

  /* background-image: linear-gradient(
    50deg,
    hsl(256deg 93% 61%) 0%,
    hsl(256deg 74% 68%) 2%,
    hsl(256deg 55% 75%) 10%,
    hsl(257deg 37% 81%) 24%,
    hsl(255deg 19% 88%) 46%,
    hsl(0deg 0% 95%) 90%
  ); */

  background: linear-gradient(
    209deg,
    transparent 0%,
    rgba(255, 255, 255, 0.004) 16%,
    rgba(255, 255, 255, 0.069) 51%,
    #ee9e7e 100%
  );

  z-index: -1;
}

.digitalInnerFlowThrough2Wrap {
  width: 100%;
  /* min-width: 320px; */
  height: 25vh;
  min-height: 270px;

  overflow: hidden;
}

.digitalInnerFlowThrough2 {
  width: 100%;
  height: 100%;
  background-color: #fff;

  border-radius: 15px;
  border: 5px solid #e4e7ec;

  position: relative;
  bottom: -2em;

  overflow: hidden;
}

.imgText2 {
  width: 700px;
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.5rem, 2vw, 2rem);
  font-weight: 100;

  padding: 0.45em 0.6em;

  background-color: #ff5805;
  /* border: 20px solid #ff84462f; */

  border: 5px solid #e4e7ec;
  border-radius: 60px;

  position: absolute;
  top: 1.5em;
  left: 4.5em;

  color: #fff;
}

.digitalSolutionHead2 {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.8rem, 2.1vw, 2.1rem);
  font-weight: 800;
}

.digitalSolutionSubText2 {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1.1vw, 1.1rem);
  font-weight: 500;

  color: #475467;

  line-height: 1.4;
}

.digitalSolutionImg2 {
  width: 40px;

  margin-top: 1em;
}

.whatOurClientSay {
  width: 100%;

  margin-inline: auto;
}

.whatOurClientSayHead {
  width: 30%;
  min-width: 200px;
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1.1vw, 1.1rem);
  font-weight: 100;

  padding: 1em;

  background-color: #3772ff;
  /* border: 20px solid #ff84462f; */

  border: 14px solid #e4e7ec;
  border-radius: 60px;

  color: #fff;

  margin-inline: auto;

  margin-top: 5em;
  margin-bottom: 2em;
}

.whatClientSayScroller {
  width: 100%;
  /* height: 600px; */

  background-color: #3772ff;
  margin-inline: auto;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  padding: 5em 0em 8em 0em;

  overflow: hidden;
}

.wallOfLove {
  width: 30%;
  min-width: 150px;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  gap: 0.5em;

  background-color: #ffffff1c;

  border-radius: 60px;

  padding: 1.2em 0.5em;

  margin-inline: auto;
}

.wallOfLoveText {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1.1vw, 1.1rem);
  font-weight: 400;

  color: #fff;

  /* padding: 1em; */
}

.loveIcon {
  width: 20px;
}

.innerWhatClientSayScroller {
  width: 100%;

  margin-inline: auto;

  display: flex;
  flex-direction: row;

  align-items: flex-start;
  justify-content: center;

  gap: 1em;

  margin-top: 3em;

  animation: commentScroll 20s linear infinite;
}

@keyframes ScrollingLefts {
  to {
    left: -330px;
  }
}

.innerWhatClientSayScroller {
  /* width: 100%; */
  width: clamp(65.625rem, 31.829vw + 55.44rem, 100rem);
  height: 35vh;

  /* display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: flex-start;

  gap: 1em; */

  position: relative;
  /* left: 4em; */
}

.commentContainer {
  /* width: 20%; */
  width: 330px;

  background-color: #fff;
  border-radius: 24px;

  padding: 2em 1.5em;

  position: absolute;
  left: 100%;

  margin-left: 1em;

  animation: ScrollingLefts 20s linear infinite;
}

.commentClientCount1 {
  animation-delay: calc(40s / 8 * (6 - 1) * -1);
}

.commentClientCount2 {
  animation-delay: calc(40s / 8 * (6 - 2) * -2);
}

.commentClientCount3 {
  animation-delay: calc(40s / 8 * (6 - 3) * -1);
}

.commentClientCount4 {
  animation-delay: calc(40s / 8 * (6 - 4) * -1);
}

.commentClientCount5 {
  animation-delay: calc(40s / 8 * (6 - 5) * -1);
}

.commentClientCount6 {
  animation-delay: calc(40s / 8 * (6 - 6) * -1);
}

.commentClientCount7 {
  animation-delay: calc(40s / 8 * (6 - 7) * -1);
}

.commentClientCount8 {
  animation-delay: calc(40s / 8 * (6 - 8) * -1);
}

.commentClientCount9 {
  animation-delay: calc(40s / 8 * (6 - 9) * -1);
}

.commentClientCount10 {
  animation-delay: calc(40s / 8 * (6 - 10) * -1);
}

.commentClientCount11 {
  animation-delay: calc(40s / 8 * (6 - 11) * -1);
}

.commentClientCount12 {
  animation-delay: calc(40s / 8 * (6 - 12) * -1);
}

.commentClientCount13 {
  animation-delay: calc(40s / 8 * (6 - 13) * -1);
}

.commentClientCount14 {
  animation-delay: calc(40s / 8 * (6 - 14) * -1);
}

.commentClientCount15 {
  animation-delay: calc(40s / 8 * (6 - 15) * -1);
}

/* .commentContainer {
  width: 30%;
  min-width: 330px;

  background-color: #fff;
  border-radius: 24px;

  padding: 2em 1.5em;
} */

.commentText {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.95rem, 1.1vw, 1.1rem);
  font-weight: 400;

  color: #98a2b3;

  margin-bottom: 2em;
}

.dpAndStatus {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: flex-start;

  gap: 0.8em;
}

.dpStatus {
  width: 50px;
  aspect-ratio: 1/1;

  border-radius: 100%;

  object-fit: cover;
}

.nameAndTitle {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-self: flex-start;

  gap: 0.2em;
}

.statusName {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1rem, 1.1vw, 1.1rem);
  font-weight: 600;

  color: #646f81;
}

.statusTitle {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1rem, 1.1vw, 1.1rem);
  font-weight: 300;

  color: #30303d;
}

/* @keyframes commentScroll {
  0% {
    transform: translate(0);
  }
  100% {
    transform: translate(calc(-70% * 3));
  }
} */

.whatWeSayContact {
  width: 60%;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  gap: 1.5em;

  padding: 3em 0em;

  margin-inline: auto;
}

.weAreHereToMeet {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.4rem, 1.6vw, 1.6rem);
  font-weight: 600;

  text-align: center;
}

.tryFeghas {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1.6vw, 1.6rem);
  font-weight: 400;

  text-align: center;
}

.whatClientSayContactUs {
  /* width: 30%;
    min-width: 200px; */
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1.1vw, 1.1rem);
  font-weight: 100;

  padding: 1em 1.5em;

  background-color: #3772ff;
  /* border: 20px solid #ff84462f; */

  border: 10px solid #e4e7ec;
  border-radius: 60px;

  color: #fff;

  transition: background-color 0.3s color 0.3s font-weight 0.3s;
}

.whatClientSayContactUs:hover {
  background-color: #ffd20c;
  color: #121212;
  font-weight: 600;
}

footer {
  width: 100%;

  background-color: #000000;
  color: #fff;

  margin-inline: auto;
}

.innerFooter {
  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  gap: 2em;

  padding: 2em 1.5em;
}

.topFooter {
  width: 100%;
  max-width: 1390px;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 1.5em;
}

.footerFirstWrap {
  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 1.5em;

  position: relative;
}

.footerFeghasLogo {
  width: 80px;
}

.footTitleList {
  list-style-type: none;
  line-height: 2;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;
}

.footTitleList:nth-child(4) {
  /* background-color: red; */

  position: absolute;
  top: 13.2em;
  left: 8em;
}

.footTitle {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.1rem, 1.2vw, 1.2rem);
  font-weight: 600;
}

.prod {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.8rem, 1vw, 1rem);
  font-weight: 100;

  color: #fff;
}

.newsLetter {
  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 1.3em;

  /* background-color: red; */
}

.news {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.1em, 1.2vw, 1.2rem);
  font-weight: 500;

  text-transform: capitalize;
}

.newsForm {
  width: 100%;

  position: relative;
}

.newsEmailLabel {
  display: none;
}

.newsInput {
  width: 100%;

  border-radius: 13px;
  border: none;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9em, 1.2vw, 1.2rem);
  font-weight: 400;

  padding: 1.2em 10rem 1.2em 1em;

  /* position: relative; */
}

.newsInput:focus {
  border: none;
  outline: none;
}

.newsBtn {
  background-color: #3772ff;

  border: none;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9em, 1.2vw, 1.2rem);
  font-weight: 400;

  color: #fff;

  padding: 0.6em 1.2em;

  position: absolute;
  top: 0.65em;
  right: 0.7em;

  border-radius: 10px;

  transition: 0.3s;
}

.newsBtn:hover {
  background-color: #ffd20c;
  color: #000000;
  cursor: pointer;
}

.copyrightAndSocial {
  width: 100%;
  max-width: 1390px;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  gap: 1em;

  margin-inline: auto;
}

.socials {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  gap: 2em;
}

.footerSocials {
  width: 23px;
}

.fb {
  width: 18.5px;
}

.copyright {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9em, 1.2vw, 1.2rem);
  font-weight: 100;
}

@media (min-width: 640px) {
  .heroSubtext {
    width: 70%;

    font-size: clamp(0.9rem, 1.2vw, 1.2rem);
  }

  .heroBtns {
    width: 65%;
  }

  .offerDisplayWrap {
    width: 100%;

    gap: 2em;

    /* background-color: rebeccapurple; */
  }

  .offerDisplay {
    width: 45%;
  }
}

@media (min-width: 700px) {
  .heroBtns {
    width: 52%;
  }

  .innerIconWrap {
    width: 50%;
    gap: 1em;

    left: 0em;

    margin-inline: auto;
  }

  .firstIconWrap {
    width: 38%;
  }

  .secondIconWrap {
    width: 60%;
    border: 1px solid #3772ff;

    /* border-radius: 10px; */

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: flex-start;

    padding: 0.4em 1em 0.35em 1em;

    gap: 0.9em;

    position: relative;

    margin: 0.1em 0em;
  }

  .nodges {
    width: 10%;
    height: 50px;

    /* background-color: red; */

    position: absolute;
    top: 0;
    right: 0;
  }

  .nodges::before {
    content: " ";
    width: 4px;
    height: 4px;

    border: 1px solid #3772ff;
    background-color: #fff;

    position: absolute;
    top: -0.2em;
    right: -0.2em;
  }

  .nodges::after {
    content: " ";
    width: 4px;
    height: 4px;

    border: 1px solid #3772ff;
    background-color: #fff;

    position: absolute;
    bottom: 0.15em;
    right: -0.25em;
  }

  .offerDisplayWrap {
    justify-content: center;
  }
}

@media (min-width: 845px) {
  .heroText {
    width: 85%;

    font-size: clamp(3.2rem, 3.4vw, 3.4rem);
    /* background-color: red; */
  }

  .secondIconWrap {
    width: 60%;
    padding: 0.8em 1em 0.5em 1em;
  }

  .heroSubtext {
    width: 45%;
  }

  .heroBtns {
    width: 42%;
    padding: 0.8em 1em;
  }

  .aboutText {
    margin-top: 4em;
  }

  .aboutHead {
    font-size: clamp(2rem, 3vw, 3rem);
  }

  .aboutSubtext {
    font-size: clamp(1rem, 1.2vw, 1.2rem);

    margin-bottom: 4em;
  }

  .h1AndLearnBtnWrap {
    width: 90%;

    flex-direction: row;
    justify-content: space-between;
  }

  .weOfferText {
    width: 60%;
  }

  .HeadText {
    width: 50%;
    font-size: clamp(2rem, 2.1vw, 2.1rem);
  }

  .digitalSolution {
    display: flex;
    flex-direction: column;

    gap: 0.5em;
  }

  .digitalSolutionWrap {
    width: 95%;

    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    gap: 1em;

    padding: 2.5em 1.5em 1em 1.5em;
  }

  .digitalSolutionWrap::after {
    width: 100%;
    height: 300%;

    background: linear-gradient(
      209deg,
      transparent 0%,
      rgba(80, 0, 213, 0.004) 18%,
      rgba(5, 0, 252, 0.069) 55%,
      #794dc2 100%
    );
  }

  .digitalSolutionImageAndTextWrap {
    width: 40%;

    /* background-color: red; */
  }

  .digitalSolutionImg {
    width: 40px;
  }

  .digitalSolutionHead {
    width: 85%;
    font-size: clamp(1.3rem, 2vw, 2rem);
  }

  .digitalSolutionSubText {
    width: 100%;
    font-size: clamp(0.7rem, 1.1vw, 1.1rem);
  }

  .digitalInnerFlowThrough {
    width: 50%;
    height: 25vh;
    min-height: 250px;
  }

  .imgText {
    width: 700px;

    font-size: clamp(1.8rem, 2vw, 2rem);

    padding: 0.5em 0.6em;
  }

  .digitalSolutionWrap2 {
    width: 95%;

    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    gap: 1em;

    padding: 5em 1.5em 3.5em 1.5em;

    position: relative;
  }

  .digitalSolutionWrap2::after {
    width: 100%;
    height: 300%;

    background: linear-gradient(
      209deg,
      transparent 0%,
      rgba(255, 255, 255, 0.004) 16%,
      rgba(255, 255, 255, 0.069) 51%,
      #ee9e7e 100%
    );
  }

  .digitalInnerFlowThrough2Wrap {
    width: 50%;
    height: 25vh;
    min-height: 320px;

    position: absolute;
    bottom: -2em;
  }

  .digitalInnerFlowThrough2 {
    width: 100%;
  }

  .digitalSolutionImageAndTextWrap2 {
    width: 38%;

    gap: 1em;

    margin-inline-start: auto;
  }

  .imgText2 {
    padding: 0.6em 0.6em;
  }

  .digitalSolutionHead2 {
    width: 75%;

    font-size: clamp(1.2rem, 1.4vw, 1.4rem);

    /* background-color: red; */
  }

  .digitalSolutionSubText2 {
    font-size: clamp(0.7rem, 1.1vw, 1.1rem);
  }

  .digitalSolutionImg2 {
    width: 35px;

    margin-top: 0em;
  }
}

@media (min-width: 725px) {
  .footerFirstWrap {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: flex-start;
    justify-content: space-around;
  }

  .newsLetter {
    width: 70%;
    max-width: 450px;

    display: flex;
    flex-direction: column;

    align-items: flex-start;
    justify-content: center;

    gap: 1.3em;

    /* background-color: red; */
  }
}

@media (min-width: 965px) {
  .heroText {
    width: 75%;

    font-size: clamp(3.2rem, 3.3vw, 3.3rem);

    /* background-color: red; */
    /* margin-top: 0.7em; */
  }

  .iconWrap {
    width: 55%;

    /* background-color: red; */
  }

  .innerIconWrap {
    width: 100%;

    gap: 1.2em;

    /* background-color: red; */
  }

  .firstIconWrap {
    width: 30%;

    padding: 0.5em 0.1em;
    justify-content: space-around;

    gap: 0.3em;
  }

  .iconSize {
    width: 34px;
    inset: 1/1;
  }

  .rectangle {
    width: 56px;
    height: 36px;

    background-color: #fff;

    border-radius: 8px;
  }

  .secondIconWrap {
    width: 48%;

    padding: 0.8em;

    margin: 0.5em;
  }

  .codeIcon {
    width: 60px;
  }

  .iconSizeCircle {
    width: 40px;
  }

  .nodges {
    width: 10%;
    height: 74px;

    /* background-color: red; */

    position: absolute;
    top: 0;
    right: 0;
  }

  .nodges::after {
    bottom: 0.35em;
  }

  .heroTextAndHeroIcon {
    gap: 0em;
  }

  .heroBtns {
    width: 38%;

    padding: 1em 1.1em;
  }

  .heroBtn {
    padding: 1em 1em;
  }

  .aboutHead {
    font-size: clamp(2.3rem, 2.1vw, 2.1rem);
  }

  .aboutSubtext {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.1rem, 1.3vw, 1.3rem);
    font-weight: 400;
  }

  .weOfferText {
    width: 60%;

    font-size: clamp(1.7rem, 2vw, 2rem);
  }

  .learnMore {
    /* width: 50%; */

    background-color: #3772ff;

    border-radius: 67px;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    padding: 0.8em 1em;
  }

  .innerWeOfferSection {
    padding: 5em 2em;

    gap: 4em;
  }

  .digitalSolutionSubText {
    font-size: clamp(0.9rem, 1.12vw, 1.2rem);
  }

  .digitalSolutionSubText2 {
    font-size: clamp(0.9rem, 1.12vw, 1.2rem);
  }

  .whatOurClientSayHead {
    width: 30%;
    min-width: 200px;
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1rem, 1.2vw, 1.2rem);
    font-weight: 100;

    padding: 1em;

    background-color: #3772ff;
    /* border: 20px solid #ff84462f; */

    border: 14px solid #e4e7ec;
    border-radius: 60px;

    color: #fff;

    margin-inline: auto;

    margin-top: 10em;
    margin-bottom: 4em;
  }

  .whatWeSayContact {
    width: 60%;

    padding: 4em 0em;
  }

  .weAreHereToMeet {
    font-size: clamp(2rem, 2.2vw, 2.2rem);
  }

  .tryFeghas {
    font-size: clamp(0.9rem, 1.3vw, 1.3rem);
  }

  .whatClientSayContactUs {
    font-size: clamp(1rem, 1.2vw, 1.2rem);
  }
}

@media (min-width: 1090px) {
  .heroText {
    width: 80%;

    font-size: clamp(4rem, 4vw, 4rem);
  }

  .heroBtns {
    width: 35%;

    padding: 1em 0.8em;

    margin-bottom: 10em;
  }

  .heroBtn {
    padding: 1.4em 1.2em;
  }

  .support {
    width: 90%;
    height: 50px;

    margin-top: 3em;
    margin-bottom: 3em;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    /* background-color: red; */

    margin-inline: auto;

    position: relative;

    /* border: 1px solid #000; */

    overflow: hidden;
  }

  .wrapper {
    width: 100%;
    height: 100%;

    position: relative;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    /* background-color: purple; */
  }

  .logoSize {
    width: min(18vw, 100px);
    aspect-ratio: 1/1;
  }

  .aboutSection {
    margin: 6em 0em;
  }

  .aboutHead {
    font-size: clamp(2.6rem, 3vw, 3rem);
  }

  .aboutSubtext {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.1rem, 1.3vw, 1.3rem);
    font-weight: 400;
  }

  .weOfferText {
    width: 62%;

    font-size: clamp(2rem, 2.1vw, 2.1rem);
  }

  .learnMore {
    /* width: 50%; */

    background-color: #3772ff;

    border-radius: 67px;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    padding: 0.8em 1em;
  }

  .innerWeOfferSection {
    padding: 5em 2em;

    gap: 4em;
  }

  .whyFeghasCall {
    margin: 6em 0em;
  }

  .whatWeSayContact {
    margin: 2em 0em;

    margin-inline: auto;
  }

  .digitalSolutionSubText {
    font-size: clamp(0.9rem, 1.12vw, 1.2rem);
  }

  .digitalSolutionSubText2 {
    font-size: clamp(0.9rem, 1.12vw, 1.2rem);
  }

  .HeadText {
    width: 45%;

    font-size: clamp(2.3rem, 2.7vw, 2.7rem);

    /* background-color: red; */
  }

  .digitalSolutionHead {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.6rem, 2.1vw, 2.1rem);
    font-weight: 800;
  }

  .digitalSolutionHead2 {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.6rem, 2.1vw, 2.1rem);
    font-weight: 800;
  }

  .innerFooter {
    padding: 3em 1em;

    gap: 4em;
  }

  .topFooter {
    width: 100%;

    flex-direction: row;
    justify-content: center;

    gap: 2em;
    /* padding: 1em 4em; */
  }

  .footerFirstWrap {
    width: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: flex-start;
    justify-content: space-between;

    gap: 1.5em;
  }

  .footTitleList:nth-child(4) {
    /* background-color: red; */

    position: relative;
    top: 0em;
    left: 0em;
  }

  .newsLetter {
    width: 40%;
    max-width: 450px;

    display: flex;
    flex-direction: column;

    align-items: flex-start;
    justify-content: center;

    gap: 1.3em;

    /* background-color: red; */
  }
}

@media (min-width: 1175px) {
  .heroText {
    width: 83%;

    font-size: clamp(4.6rem, 5vw, 5rem);

    /* background-color: red; */
  }

  .iconWrap {
    width: 55%;

    /* background-color: red; */
  }

  .innerIconWrap {
    width: 100%;

    gap: 1.2em;

    /* background-color: red; */
  }

  .firstIconWrap {
    width: 25%;

    padding: 0.5em 0.5em;
  }

  .iconSize {
    width: 40px;
    inset: 1/1;
  }

  .rectangle {
    width: 80px;
    height: 45px;

    background-color: #fff;

    border-radius: 8px;
  }

  .secondIconWrap {
    width: 48%;

    padding: 0.9em;

    margin: 0.5em;
  }

  .codeIcon {
    width: 60px;
  }

  .iconSizeCircle {
    width: 40px;
  }

  .nodges {
    width: 10%;
    height: 74px;

    /* background-color: red; */

    position: absolute;
    top: 0;
    right: 0;
  }

  .nodges::after {
    bottom: 0.35em;
  }

  .heroTextAndHeroIcon {
    gap: 0em;
  }

  .heroBtns {
    width: 35%;

    padding: 1em 0.8em;

    margin-bottom: 12em;
  }

  .heroBtn {
    padding: 1.9em 2.3em;
  }

  .support {
    width: 90%;
    height: 50px;

    margin-top: 1em;
    margin-bottom: 1em;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    /* background-color: red; */

    margin-inline: auto;

    position: relative;

    /* border: 1px solid #000; */

    overflow: hidden;
  }

  .wrapper {
    width: 100%;
    height: 100%;

    position: relative;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    /* background-color: purple; */
  }

  .logoSize {
    width: min(18vw, 100px);
    aspect-ratio: 1/1;
  }

  .aboutSection {
    margin: 6em 0em;
  }

  .aboutHead {
    font-size: clamp(3.1rem, 3.2vw, 3.2rem);
  }

  .aboutSubtext {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.35em, 1.4vw, 1.4rem);
    font-weight: 400;

    margin-top: 1em;

    line-height: 1.4;
  }

  .weOfferText {
    width: 62%;

    font-size: clamp(2.3rem, 2.4vw, 2.4rem);
  }

  .learnMore {
    /* width: 50%; */

    background-color: #3772ff;

    border-radius: 67px;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    padding: 0.8em 1em;
  }

  .innerWeOfferSection {
    padding: 5em 2em;

    gap: 4em;
  }

  .whyFeghasCall {
    margin: 6em 0em;
  }

  .whatWeSayContact {
    margin: 2em 0em;

    margin-inline: auto;
  }

  .digitalSolutionSubText {
    font-size: clamp(1rem, 1.12vw, 1.2rem);
  }

  .digitalSolutionSubText2 {
    font-size: clamp(1rem, 1.12vw, 1.2rem);
  }

  .HeadText {
    width: 45%;

    font-size: clamp(2.3rem, 2.7vw, 2.7rem);

    /* background-color: red; */
  }

  .digitalSolutionHead {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.8rem, 2.1vw, 2.1rem);
    font-weight: 800;
  }

  .digitalSolutionHead2 {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.8rem, 2.1vw, 2.1rem);
    font-weight: 800;
  }

  .whatWeSayContact {
    margin: 3em 0em;

    margin-inline: auto;
  }

  .weAreHereToMeet {
    font-size: clamp(2.5rem, 2.7vw, 2.7rem);
  }

  .innerFooter {
    padding: 3em 1em;

    gap: 4em;
  }

  .topFooter {
    width: 100%;

    flex-direction: row;
    justify-content: center;

    gap: 2em;
  }

  .footerFirstWrap {
    width: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: flex-start;
    justify-content: space-between;

    gap: 1.5em;
  }

  .footTitleList:nth-child(4) {
    /* background-color: red; */

    position: relative;
    top: 0em;
    left: 0em;
  }

  .newsLetter {
    width: 40%;
    max-width: 450px;

    display: flex;
    flex-direction: column;

    align-items: flex-start;
    justify-content: center;

    gap: 1.3em;

    /* background-color: red; */
  }
}

@media (min-width: 724px) {
  .footTitleList:nth-child(4) {
    /* background-color: red; */

    position: relative;
    top: 0em;
    left: 0em;
  }
}

@media (min-width: 1400px) {
  .heroText {
    width: 85%;

    font-size: clamp(3.5rem, 4.5vw, 4.5rem);

    /* background-color: red; */
  }

  .heroText:nth-child(1) {
    /* color: red; */
    /* display: block; */
  }

  .dispBlck {
    display: block;
  }

  .iconWrap {
    width: 55%;

    /* background-color: red; */
  }

  .innerIconWrap {
    width: 100%;

    gap: 1.2em;

    /* background-color: red; */
  }

  .firstIconWrap {
    width: 25%;

    padding: 0.5em 0.5em;
  }

  .iconSize {
    width: 40px;
    inset: 1/1;
  }

  .rectangle {
    width: 80px;
    height: 45px;

    background-color: #fff;

    border-radius: 8px;
  }

  .secondIconWrap {
    width: 48%;

    padding: 0.9em;

    margin: 0.5em;
  }

  .codeIcon {
    width: 60px;
  }

  .iconSizeCircle {
    width: 40px;
  }

  .nodges {
    width: 10%;
    height: 74px;

    /* background-color: red; */

    position: absolute;
    top: 0;
    right: 0;
  }

  .nodges::after {
    bottom: 0.35em;
  }

  .heroTextAndHeroIcon {
    gap: 0em;
  }

  .heroBtns {
    width: 35%;

    padding: 1em 0.8em;

    margin-bottom: 12em;
  }

  .heroBtn {
    padding: 1.9em 2.3em;
  }

  .support {
    width: 90%;
    height: 50px;

    margin-top: 0em;
    margin-bottom: 0em;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    /* background-color: red; */

    margin-inline: auto;

    position: relative;

    /* border: 1px solid #000; */

    overflow: hidden;
  }

  .wrapper {
    width: 100%;
    height: 100%;

    position: relative;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    /* background-color: purple; */
  }

  .logoSize {
    width: min(18vw, 100px);
    aspect-ratio: 1/1;
  }

  .aboutSection {
    margin: 6em 0em;
  }

  .aboutHead {
    font-size: clamp(3.1rem, 3.2vw, 3.2rem);
  }

  .aboutSubtext {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.35em, 1.4vw, 1.4rem);
    font-weight: 400;

    margin-top: 1em;

    line-height: 1.4;
  }

  .weOfferText {
    width: 50%;

    font-size: clamp(2rem, 2vw, 2.4rem);
  }

  .learnMore {
    /* width: 50%; */

    background-color: #3772ff;

    border-radius: 67px;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    padding: 0.8em 1em;
  }

  .innerWeOfferSection {
    padding: 5em 2em;

    gap: 4em;
  }

  .whyFeghasCall {
    margin: 6em 0em;
  }

  .whatWeSayContact {
    margin: 2em 0em;

    margin-inline: auto;
  }

  .digitalSolutionWrap {
    width: 95%;

    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    gap: 1em;

    padding: 1em 4em 2em 4em;

    position: relative;
  }

  .digitalSolutionWrap2 {
    width: 95%;

    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    gap: 1em;

    padding: 6em 4em 5em 4em;

    position: relative;
  }

  .digitalInnerFlowThrough {
    width: 50%;
    height: 30vh;
    min-height: 350px;

    bottom: -3em;
  }

  .digitalInnerFlowThrough2Wrap {
    /* width: 50%; */
    height: 30vh;
    min-height: 350px;

    bottom: -1em;
  }

  .digitalSolutionSubText {
    font-size: clamp(1.1rem, 1.2vw, 1.2rem);
  }

  .digitalSolutionSubText2 {
    font-size: clamp(1.1rem, 1.2vw, 1.2rem);
  }

  .HeadText {
    width: 45%;

    font-size: clamp(2.3rem, 2.7vw, 2.7rem);

    /* background-color: red; */
  }

  .digitalSolutionHead {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.8rem, 1.8vw, 1.8rem);
    font-weight: 800;
  }

  .digitalSolutionHead2 {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.8rem, 1.8vw, 1.8rem);
    font-weight: 800;
  }

  .whatWeSayContact {
    margin: 3em 0em;

    margin-inline: auto;
  }

  .whatOurClientSayHead {
    width: 20%;
    text-align: center;

    font-size: clamp(1.3rem, 1.4vw, 1.4rem);
  }

  .wallOfLove {
    width: 20%;
  }

  .weAreHereToMeet {
    font-size: clamp(2.5rem, 2.7vw, 2.7rem);
  }

  .innerFooter {
    padding: 3em 1em;

    gap: 4em;
  }

  .topFooter {
    width: 100%;

    flex-direction: row;
    justify-content: center;

    gap: 2em;
  }

  .footerFirstWrap {
    width: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: flex-start;
    justify-content: space-between;

    gap: 1.5em;
  }

  .footTitleList:nth-child(4) {
    /* background-color: red; */

    position: relative;
    top: 0em;
    left: 0em;
  }

  .newsLetter {
    width: 40%;
    max-width: 450px;

    display: flex;
    flex-direction: column;

    align-items: flex-start;
    justify-content: center;

    gap: 1.3em;

    /* background-color: red; */
  }
}

@media (max-width: 1440px) {
  .headerWrapper {
    width: 80%;
    /* max-width: 2000px; */
  }

  nav {
    width: 50%;

    /* background-color: yellowgreen;
        display: flex;
        flex-direction: row;
    
        align-items: center;
        justify-content: center; */
  }

  .navB {
    width: 73%;

    /* background-color: #8b5050; */
  }

  .navLink {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.8rem, 1.25vw, 1.25rem);
  }

  .contactBtnn {
    font-size: clamp(1rem, 1.05vw, 1.05rem);
  }
}

@media (max-width: 1280px) {
  .headerWrapper {
    width: 85%;
    /* max-width: 2000px; */
  }

  nav {
    width: 58%;

    /* background-color: yellowgreen;
        display: flex;
        flex-direction: row;
    
        align-items: center;
        justify-content: center; */
  }

  .navB {
    width: 80%;
  }

  .navLink {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.05rem, 1.25vw, 1.25rem);
  }

  .contactBtnn {
    font-size: clamp(1rem, 1.05vw, 1.05rem);
  }
}

@media (max-width: 1176px) {
  .headerWrapper {
    width: 80%;
  }

  nav {
    width: 55%;

    /* background-color: yellowgreen;
        display: flex;
        flex-direction: row;
    
        align-items: center;
        justify-content: center; */
  }

  .navB {
    width: 75%;
  }

  .navLink {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.8rem, 1.25vw, 1.25rem);
  }

  .feghasLogo {
    width: 100px;
    inset: 1/1;
  }

  .contactBtnn {
    font-size: clamp(0.9rem, 1.05vw, 1.05rem);
  }
}

@media (max-width: 1145px) {
  .headerWrapper {
    width: 80%;
  }

  nav {
    width: 52%;

    /* background-color: yellowgreen;
        display: flex;
        flex-direction: row;
    
        align-items: center;
        justify-content: center; */
  }

  .navB {
    width: 75%;
  }

  .navLink {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.8rem, 1.25vw, 1.25rem);
  }

  .contactBtnn {
    font-size: clamp(0.8rem, 1.05vw, 1.05rem);
  }
}

@media (max-width: 1114px) {
  .headerWrapper {
    width: 82%;
  }

  nav {
    width: 56%;

    /* background-color: yellowgreen;
        display: flex;
        flex-direction: row;
    
        align-items: center;
        justify-content: center; */
  }

  .navB {
    width: 75%;
  }

  .navLink {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.8rem, 1.25vw, 1.25rem);
  }

  .contactBtnn {
    font-size: clamp(0.8rem, 1.05vw, 1.05rem);
  }
}

@media (max-width: 1114px) {
  .headerWrapper {
    width: 83%;

    position: relative;
  }

  nav {
    width: 56%;

    /* background-color: yellowgreen;
        display: flex;
        flex-direction: row;
    
        align-items: center;
        justify-content: center; */
  }

  .navB {
    width: 80%;
  }
}

@media (max-width: 946px) {
  .responsiveNav {
    display: none;
  }

  .sr-only {
    display: block;
    visibility: visible;
    opacity: 1;

    cursor: pointer;
  }

  .menu {
    width: 27px;

    z-index: 999;
    margin-left: 1em;
  }

  nav {
    width: 100%;
    /* height: 100%; */

    position: fixed;
    inset: 0 0 0 20%;
    background-color: rgb(255, 255, 255);

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: flex-start;

    padding: min(30vh, 10rem) 2rem;

    box-shadow: 0px 0px 40px 0px rgb(230, 227, 227);

    background: hsl(0 0% 100% / 0.1);

    backdrop-filter: blur(3em);
    z-index: 100;
  }

  .navList {
    width: 100%;

    list-style: none;

    display: flex;
    flex-direction: column;

    align-items: flex-start;
    justify-content: space-evenly;

    gap: 0.5em;
  }

  .navLink {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.2rem, 1.15vw, 1.15rem);
    font-weight: 600;

    letter-spacing: -0em;

    padding: 0.5em 0.6em;

    border-radius: 10px;

    cursor: pointer;

    transition: background-color 0.3s;
  }

  .navLink:hover {
    background-color: #aabdf027;
  }

  .home {
    width: 27px;

    margin-right: 0.5em;
  }

  .homeIconAndText {
    display: flex;
    flex-direction: row;

    align-items: center;
  }

  .homeFeghasUndeline {
    border-bottom: 1.5px solid black;

    padding-bottom: 0.2em;
  }

  .feghasLogo {
    width: 110px;
    inset: 1/1;
  }

  .contactBtnn {
    /* width: 100%;
    height: 100%;

    position: fixed;
    inset: 0 0 0 0;
    background-color: rgba(235, 0, 0, 0.315);

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: flex-start;

    padding: min(30vh, 10rem) 2rem; */

    position: relative;
    inset: 0 0 0 0;
    margin-inline-start: auto;

    border: 0.1px solid #000;

    background: #000;
    color: #fff;

    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.8rem, 1.05vw, 1.05rem);
    font-weight: 600;

    padding: 0.55em 1.2em;

    border-radius: 60px;

    cursor: pointer;

    z-index: 100;
  }

  .contactBtnn:hover {
    background-color: #fff;
    color: #000;
    border-color: #dbdbdb;
  }
}

@media (max-width: 450px) {
  nav {
    padding: min(30vh, 10rem) 0rem;
  }
}

@media (max-width: 370px) {
  .feghasLogo {
    width: 110px;
    inset: 1/1;

    /* display: none; */
  }
}

.firstSectionAboutPG {
  width: 100%;

  margin: 4em 0em;
  margin-top: 9em;

  margin-inline: auto;
}

.innerFirstSectionAboutUsPG {
  width: 90%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 1.5em;

  margin-inline: auto;

  /* background-color: rebeccapurple; */
}

.headTextAbtUs {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9em, 0.9vw, 0.9rem);
  font-weight: 500;

  color: #3772ff;
  background-color: #3772ff1a;
  border-radius: 70px;

  padding: 1.5em 2em;
}

.aboutUsHeroTextWrap {
  width: 100%;
}

.heroPhrase {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.8em, 3vw, 3rem);
  font-weight: 600;

  line-height: 1.05;
}

.orange {
  color: #ffb900;
}

.pictureDisplay {
  width: 100%;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  gap: 2em;
}

.firstFrame {
  width: 60%;
  height: 400px;

  background-color: #d9d9d9;
  background-image: url(./assets/feghasGrp.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  border-radius: 30px 30px 100px 30px;
}

.secondFrame {
  width: 40%;
  height: 400px;

  background-color: #d9d9d9;
  background-image: url(./assets/feghasGroup2.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  border-radius: 30px 30px 30px 90px;
}

.ourStory {
  width: 100%;

  margin-inline: auto;
}

.innerOurStory {
  width: 90%;

  margin: 10em 0em;
  margin-inline: auto;

  display: flex;
  flex-direction: row;

  align-items: flex-start;
  justify-content: space-between;

  gap: 1em;
}

.ourStoryHeadCall {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  gap: 0.5em;

  padding: 1em 1.5em;

  border-radius: 60px;
  border: 15px solid #e4e7ec;

  background-color: #3772ff;
}

.ourStoryText {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.2rem, 2vw, 2rem);
  font-weight: 500;

  color: #fff;
}

.arrowDiagIcon {
  width: 30px;
}

.ourStoryExplains {
  width: 60%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 1em;
}

.ourStoryHeadText {
  width: 62%;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.2rem, 2.5vw, 2.5rem);
  font-weight: 600;
}

.blueClor {
  color: #3772ff;
}

.greenClr {
  color: green;
}

.ourStorySubtextFirst {
  /* width: 90%; */

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1rem, 1.3vw, 1.3rem);
  font-weight: 400;

  line-height: 1.6;
}

.ourTeam {
  width: 100%;

  margin: 10em 0em;

  margin-inline: auto;
}

.innerOurTeam {
  width: 90%;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  gap: 4em;

  margin-inline: auto;
}

.ourTeamHeadWrap {
  width: 70%;

  margin-inline: auto;

  text-align: center;
}

.firstOurTeamhead {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.8rem, 2.8vw, 2.8rem);
  font-weight: 600;

  display: block;
}

.secondOurTeamHead {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.8rem, 2.8vw, 2.8rem);
  font-weight: 600;

  /* display: block; */
  color: #919191;
  line-height: 1;
}

.boardOdDirWrap {
  width: 90%;

  /* margin-inline: auto; */
}

.boardHeadText {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.4rem, 1.8vw, 1.8rem);
  font-weight: 700;

  text-align: center;

  margin-top: 0.5em;
}

.boardOfDirSubText {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.7rem, 1.1vw, 1.1rem);
  font-weight: 300;

  text-align: center;

  margin-top: 0.5em;
}

.directorsWrap {
  width: 80%;
  max-width: 950px;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30%, 200px));

  grid-gap: 2em 0em;

  place-items: center;
  place-content: center;

  /* background-color: red; */

  margin-inline: auto;
  margin-top: 3em;
}

.leadershipWrap {
  width: 80%;
  max-width: 950px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: center;
  justify-content: center;

  /* background-color: purple; */

  gap: 2em;

  margin-top: 3em;
  margin-inline: auto;
}

.pictureAndStatus {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  gap: 1.5em;
}

.leadersPictureAndStatus {
  width: 30%;
  min-width: 180px;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  gap: 1em;

  /* background-color: red; */
}

.directorImg {
  width: 21%;
  min-width: 50px;
  aspect-ratio: 1/1;

  border-radius: 100%;

  object-fit: cover;
}

.directorName {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.7rem, 1.1vw, 1.1rem);
  font-weight: 700;
}

.directorTitle {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.6rem, 1vw, 1rem);
  font-weight: 600;

  color: #3772ff;

  margin-top: 0.2em;
}

.numberRate {
  width: 100%;

  margin: 10em 0em 4em 0;

  margin-inline: auto;
}

.innerNumberRate {
  width: 90%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 1.5em;

  margin-inline: auto;
}

.headTextNumberRate {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9em, 0.9vw, 0.9rem);
  font-weight: 500;

  color: #3772ff;
  background-color: #3772ff1a;
  border-radius: 70px;

  padding: 1.5em 2em;
}

.numberRater {
  width: 100%;

  margin-inline: auto;

  padding: 5em 0em;

  border-top: 0.5px solid #c9c9c9;
  border-bottom: 0.5px solid #c9c9c9;
}

.innerNumberRater {
  width: 80%;
  max-width: 900px;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-column-gap: 3em;
  grid-row-gap: 2em;

  /* place-items: center; */

  /* background-color: red; */

  margin-inline: auto;
}

.numberHead {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(2em, 4.5vw, 4.5rem);
  font-weight: 700;

  line-height: 1;
}

.numberRateSubtext {
  width: 18%;
  min-width: 250px;

  /* background-color: red; */

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.85em, 1vw, 1rem);
  font-weight: 400;

  color: #7f7f7f;
}

@media (max-width: 900px) {
  .pictureDisplay {
    flex-direction: column;
  }

  .firstFrame {
    width: 100%;
    height: 300px;
  }

  .secondFrame {
    width: 100%;
    height: 200px;
  }

  .innerOurStory {
    width: 90%;

    margin: 5em 0em;
    margin-inline: auto;

    flex-direction: column;
  }

  .ourStoryText {
    font-size: clamp(1rem, 2vw, 2rem);
  }

  .ourStoryHeadCall {
    padding: 0.5em 1.5em;

    border: 10px solid #e4e7ec;
  }

  .arrowDiagIcon {
    width: 20px;
  }

  .ourStoryExplains {
    width: 100%;
  }

  .ourStoryHeadText {
    width: 62%;

    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.2rem, 2.5vw, 2.5rem);
    font-weight: 600;
  }
}

.solutionHero {
  width: 100%;
  height: 70vh;
  min-height: 600px;

  margin-inline: auto;

  background-image: url("./assets/diverse-group-students-taking-online-tests.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.innerSolutionHero {
  width: 100%;
  height: 100%;

  background: #000000c4;

  padding: 3em;

  position: relative;
}

.innerSolutionHero::after {
  content: " ";

  width: 100%;
  height: 40%;

  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.solutionHerohead {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.5em, 5vw, 5rem);
  font-weight: 400;

  color: #fff;

  /* margin: 6em 0em; */
  padding: min(40vh, 20rem) 2rem;
}

.solutionsProducts {
  width: 100%;

  margin-inline: auto;
}

.innerSolutionsProducts {
  width: 90%;

  padding: 2em 0em;

  margin: 4em 0em;

  margin-inline: auto;
}

.solutionProductHeadTxtWrap {
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 1.5em;
}

.solutionProductsHeadTxt {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.3em, 2.8vw, 2.8rem);
  font-weight: 500;

  line-height: 1.2;
}

.solutionProductSubtext {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1em, 1.2vw, 1.2rem);
  font-weight: 400;

  color: #3772ff;
}

.solutionsProductOverviews {
  width: 100%;
  max-width: 1450px;

  /* background-color: red; */

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

  grid-gap: min(15vw, 4em);

  margin-top: 4em;
}

.solutionProductCard {
  /* background-color: red; */

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: min(2vw);

  background-color: #d9d9d9;
  border-radius: 25px;

  padding: 1.5em;
}

.solutionProductOverview {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1em, 1.6vw, 1.6rem);
  font-weight: 700;
}

.solutionProductSubText {
  /* width: 85%; */
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1em, 1.2vw, 1.2rem);
  font-weight: 400;

  color: #525252;
}

.solutionsProdOverviewImg {
  width: 100%;
  /* max-width: 372px; */
  height: 220px;

  object-fit: cover;

  border-radius: 18px;
}

.solutionProducts {
  width: 100%;

  margin-inline: auto;
}

.innerSolutionProducts {
  width: 90%;

  margin-bottom: 10em;
  margin-inline: auto;

  /* background-color: red; */
}

.sectionTitle {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.4em, 2.3vw, 2.3rem);
  font-weight: 600;

  /* margin-top: 2em; */
  margin-inline-end: auto;
}

.eduProductWrap {
  width: 100%;

  /* background-color: rebeccapurple; */

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  gap: 4em;

  margin: 4em 0em;
}

.productContent {
  width: 100%;

  /* background-color: rebeccapurple; */

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  gap: 1em;
}

.productContentText {
  width: 50%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 1.5em;

  margin-top: 1em;
}

.ourProduct {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9em, 1.1vw, 1.1rem);
  font-weight: 400;

  text-transform: uppercase;

  color: #3772ff;
}

.productTitle {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.3em, 2.8vw, 2.8rem);
  font-weight: 500;

  text-transform: uppercase;

  margin-top: 0.2em;
}

.productSubtext {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.8em, 1vw, 1rem);
  font-weight: 400;

  color: #525252;
}

.productsCategory {
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: center;
  justify-content: flex-start;

  gap: 1em;
}

.productCategoryTitle {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9em, 1.1vw, 1.1rem);
  font-weight: 400;

  background-color: #f3f3f3;

  padding: 0.75em;
}

.productContentImage {
  width: 40dvw;
  min-width: 350px;

  border-radius: 15px;
}

.ProductsWhatClientSay {
  width: 100%;

  margin-inline: auto;
}

.innerProductsWhatClientSay {
  width: 90%;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: flex-start;

  gap: 2em;

  margin-inline: auto;

  /* background-color: red; */

  overflow: hidden;
}

.prodClientSayImageArea {
  width: 45%;

  object-fit: cover;

  position: relative;
  bottom: -3em;
}

.prodClientHeadTxtAndButton {
  width: 50%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 0.7em;
}

.prodClientHead {
  width: 70%;
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.1em, 2.8vw, 2.8rem);
  font-weight: 400;

  line-height: 1.2;
}

.contactUs {
  padding: 1em 2em;

  background-color: #3772ff;

  border: 14px solid #e4e7ec;
  border-radius: 60px;

  color: #fff;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.7em, 1.1vw, 1.1rem);
  font-weight: 400;

  transition: background-color 0.3s color 0.3s;

  /* margin-top: 1em; */
}

.contactUs:hover {
  background-color: #ffb900;
  cursor: pointer;

  color: #000;
  font-weight: 600;
}

@media (max-width: 1256px) {
  .solutionProductCard {
    /* background-color: red; */

    gap: 1em;

    border-radius: 15px;

    padding: 1em;
  }

  .solutionsProdOverviewImg {
    width: 100%;
    /* max-width: 372px; */
    height: 190px;

    object-fit: cover;

    border-radius: 18px;
  }
}

@media (max-width: 1080px) {
  .solutionProductCard {
    /* background-color: red; */

    gap: 1em;

    border-radius: 15px;

    padding: 1em;
  }

  .solutionsProdOverviewImg {
    width: 100%;
    /* max-width: 372px; */
    height: 140px;

    object-fit: cover;

    border-radius: 10px;
  }
}

@media (max-width: 890px) {
  .innerSolutionProducts {
    width: 90%;

    margin-bottom: 10em;
    margin-inline: auto;

    /* background-color: red; */
  }

  .sectionTitle {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.4em, 2.3vw, 2.3rem);
    font-weight: 600;

    /* margin-top: 2em; */
    margin-inline-end: auto;
  }

  .eduProductWrap {
    width: 100%;

    /* background-color: rebeccapurple; */

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    gap: 4em;

    margin: 4em 0em;
  }

  .productContent {
    width: 100%;

    /* background-color: rebeccapurple; */

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: space-between;

    gap: 1em;
  }

  .productContentText {
    width: 100%;

    margin-top: 1em;
  }

  .ourProduct {
    font-size: clamp(0.9em, 1.1vw, 1.1rem);
  }

  .productTitle {
    font-size: clamp(1.5em, 4vw, 4rem);

    margin-top: 0.2em;
  }

  .productSubtext {
    font-size: clamp(1em, 1.5vw, 1.5rem);
  }

  .productCategoryTitle {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.9em, 1.1vw, 1.1rem);
    font-weight: 400;

    background-color: #f3f3f3;

    padding: 0.75em;
  }

  .productContentImage {
    width: 100%;
    min-width: 350px;

    border-radius: 10px;
  }

  .innerProductsWhatClientSay {
    width: 90%;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: flex-start;

    gap: 1em;

    margin-inline: auto;

    /* background-color: red; */

    overflow: hidden;
  }

  .prodClientSayImageArea {
    width: 50%;

    object-fit: cover;

    position: relative;
    bottom: -3em;
  }

  .prodClientHeadTxtAndButton {
    width: 70%;

    background-color: re;
  }

  .prodClientHead {
    width: 100%;
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.1em, 2.8vw, 2.8rem);
    font-weight: 400;

    line-height: 1.2;

    margin-bottom: 0.7em;
  }

  .solutionHerohead {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(3em, 5vw, 5rem);
    font-weight: 400;

    color: #fff;

    /* margin: 6em 0em; */
    padding: min(40vh, 20rem) 2rem;
  }
}

.careers {
  width: 100%;
  height: 100dvh;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  margin-inline: auto;
}

.innerCareers {
  width: 100%;
  max-width: 1350px;
  height: 98dvh;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  /* margin: 1em 0em; */
  margin-inline: auto;
}

.primaryContent {
  width: 45%;
  height: 100%;

  background-color: #1362f3;

  padding: 5em;

  position: relative;

  overflow: hidden;
}

.secondaryContent {
  width: 55%;
  height: 100%;

  background-color: #ffffff;

  padding: 5em 4em;

  overflow-y: scroll;

  position: relative;
}

.joinHeadTxt {
  width: 80%;
  min-width: 300px;
}

.joinTxt {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(3em, 4vw, 4rem);
  font-weight: 400;

  line-height: 1.1;

  color: #fff;

  /* background-color: red; */
  margin-top: 0.7em;
}

.joinSubtext {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.2em, 1.5vw, 1.5rem);
  font-weight: 300;

  color: #fff;

  margin-top: 0.5em;
}

.laptopIcon {
  width: 650px;

  margin-top: 0em;

  /* background-color: red; */

  position: absolute;
  left: -15em;
  bottom: 3em;
}

.arrowBack {
  width: 25px;
}

.backToHome {
  /* width: 25%; */
  width: 180px;
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  /* background-color: rgba(19, 97, 243, 0.233); */

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.85em, 0.9vw, 0.9rem);
  font-weight: 400;

  position: absolute;
  top: 0em;
  left: 1em;

  color: #000;

  border-radius: 60px;
  padding: 0.4em 0.8em;

  transition: 0.3s;
}

.backToHome:hover {
  background-color: rgba(19, 97, 243, 0.233);
}

.jobDescription {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9em, 0.95vw, 0.95rem);
  font-weight: 400;
}

.nextDescript {
  display: block;

  margin-top: 1em;
}

.innerSecondaryContent {
  width: 100%;

  /* overflow-y: scroll; */
}

.careerForm {
  width: 100%;
}

.primaryDetails {
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: center;
  justify-content: flex-start;

  gap: 0.5em;
}

.labelAndInput {
  width: 48%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 0.4em;
  /* background-color: red; */

  margin-top: 1.2em;
}

.careerLabel {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9em, 1.05vw, 1.05rem);
  font-weight: 500;
}

.personalDetailsInput {
  font-family: "Space Grotesk", sans-serif;
  /* font-size: clamp(0.6em, 0.8vw, 0.8rem);
  font-weight: 300; */

  width: 100%;

  padding: 1.2em;

  border: 1px solid #12112760;
  border-radius: 5px;
}

.personalDetailsInput:focus {
  outline-style: none;
}

.displayBlock {
  width: 100%;

  margin-top: 1.5em;
}

.submitBtn {
  padding: 0.8em 1.5em;

  background-color: #3772ff;

  border: 10px solid #e4e7ec;
  border-radius: 60px;

  color: #fff;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9em, 1vw, 1rem);
  font-weight: 400;

  margin-top: 1em;

  transition: background-color 0.3s color 0.3s;
}
.submitBtn:hover {
  background-color: #ffb900;
  cursor: pointer;

  color: #000;
  font-weight: 600;
}

@media (max-width: 935px) {
  .primaryContent {
    display: none;
  }

  .secondaryContent {
    width: 100%;
    height: 100%;

    background-color: #ffffff;

    padding: 5em 4em;

    overflow-y: scroll;
  }

  .innerSecondaryContent {
    width: 100%;

    /* overflow-y: scroll; */

    /* background-color: red; */
  }

  .backToHome {
    width: 180px;
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    /* background-color: red; */

    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.85em, 0.9vw, 0.9rem);
    font-weight: 400;

    position: absolute;
    top: 0em;
    left: 1em;
  }

  .arrowBack {
    stroke: #cacaca;
  }

  .jobDescription {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.9em, 0.95vw, 0.95rem);
    font-weight: 400;
  }

  .nextDescript {
    display: block;

    margin-top: 1em;
  }

  .innerSecondaryContent {
    width: 100%;

    /* overflow-y: scroll; */
  }

  .careerForm {
    width: 100%;
  }

  .primaryDetails {
    width: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: center;
    justify-content: center;

    gap: 0.5em;
  }

  .labelAndInput {
    width: 48%;

    justify-content: center;

    margin-top: 1.2em;

    /* background-color: rebeccapurple; */
  }

  .displayBlock {
    width: 100%;

    margin-top: 1.5em;

    justify-self: center;
  }
}

@media (max-width: 550px) {
  .primaryDetails {
    width: 100%;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    align-items: center;
    justify-content: center;

    gap: 0.1em;
  }

  .labelAndInput {
    width: 100%;

    justify-content: center;

    margin-top: 1.2em;

    /* background-color: rebeccapurple; */
  }
}

.contactUsPG {
  width: 100%;

  margin-inline: auto;
}

.innerContactUs {
  width: 100%;
}

.contactUsMain {
  width: 100%;
  /* max-width: 1400px; */

  position: relative;
  margin-top: 25em;
  background-color: #000;

  margin-inline: auto;
}

.contactUsFloatingContent {
  width: 75%;

  margin-inline: auto;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 1em;

  background-color: #fff;
  border-radius: 20px;

  padding: 5em 5em;

  position: relative;
  top: -12em;

  box-shadow: 0px 0px 15px -5px #c2c2c2;
}

.contactContentWrap {
  width: 100%;

  display: flex;
  flex-direction: row;

  align-items: flex-start;
  justify-content: center;

  gap: 1em;
}

.contactContentWrapOne {
  width: 85%;
  /* background-color: red; */
}

.contactUsTitleTxt {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9em, 1vw, 1rem);
  font-weight: 500;

  text-transform: uppercase;

  color: #1362f3;
}

.contactUsHeadText {
  width: 60%;
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.6em, 2.2vw, 2.2rem);
  font-weight: 700;

  /* background-color: red; */
}

.contactUsSubtext {
  width: 85%;
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1em, 1.2vw, 1.2rem);
  font-weight: 400;

  line-height: 1.5;

  margin-top: 0.8em;
}

.contactContentWrapTwo {
  width: 40%;
  min-width: 240px;
}

.officeTxt {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1em, 1.2vw, 1.2rem);
  font-weight: 700;

  margin-bottom: 1em;
}

.addressText {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1em, 1.2vw, 1.2rem);
  font-weight: 400;

  line-height: 1.5;

  color: #6e6e6e;

  margin-bottom: 2em;
}

.phoneNumber {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9em, 1vw, 1rem);
  font-weight: 400;

  color: #6e6e6e;
}

.contactUsInfo {
  width: 65%;
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(2em, 2.2vw, 2.2rem);
  font-weight: 700;

  color: #6e6e6e;

  margin: 1em 0em 0.5em 0em;
}

.contactWrapperBtom {
  width: 100%;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: flex-start;

  margin-inline: auto;
}

.formAndCategoryWrap {
  width: 65%;
}

.contactUSImg {
  width: 28%;
}

.selectTopicWrap {
  width: 100%;
}

.selectTopicTitle {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1rem, 1.15vw, 1.15rem);
  font-weight: 600;

  margin-top: 1em;
  margin-bottom: 0.5em;
}

.topicsWrap {
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: center;
  justify-content: flex-start;

  gap: 1em;

  margin-top: 1em;
}

.topic {
  background: none;
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.85em, 0.95vw, 0.95rem);
  font-weight: 600;

  padding: 0.6em 1em;

  border: 1px solid #dbdbdb;
  border-radius: 60px;

  transition: 0.3s;
}

.topic:hover {
  background: #3773ff21;
  color: #1362f3;

  cursor: pointer;
}

.contactUsComment {
  width: 100%;

  margin-top: 1em;
}

.contactUsForm {
  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 0.3em;

  margin-top: 2em;
}

.contactUsCommentTxt {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1rem, 1.15vw, 1.15rem);
  font-weight: 600;
}

.contactUsInput {
  width: 90%;
  height: 150px;

  border-radius: 5px;

  padding: 0.5em;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1em, 0.95vw, 0.9rem);
  font-weight: 400;

  margin-top: 1em;
}

.contactUsSubmit {
  padding: 0.8em 1.5em;

  background-color: #3772ff;

  border: 10px solid #e4e7ec;
  border-radius: 60px;

  color: #fff;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9em, 1vw, 1rem);
  font-weight: 400;

  margin-top: 1em;

  transition: background-color 0.3s color 0.3s;
}

.contactUsSubmit:hover {
  background-color: #ffb900;
  cursor: pointer;

  color: #000;
  font-weight: 600;
}

@media (max-width: 1195px) {
  .contactUsFloatingContent {
    padding: 3em 3em;
  }
}

@media (max-width: 960px) {
  .contactUsFloatingContent {
    width: 85%;

    flex-direction: column;

    align-items: flex-start;
    justify-content: center;

    gap: 2em;

    background-color: #fff;
    border-radius: 20px;

    padding: 4em 3em;

    position: relative;
    top: -11em;

    box-shadow: 0px 0px 15px -5px #c2c2c2;
  }
}

@media (max-width: 810px) {
  .contactContentWrap {
    width: 100%;

    flex-direction: column;
  }

  .contactUsSubtext {
    width: 100%;
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1em, 1.2vw, 1.2rem);
    font-weight: 400;

    line-height: 1.5;

    margin-top: 0.8em;
  }

  .contactUsHeadText {
    width: 90%;
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.6em, 2.2vw, 2.2rem);
    font-weight: 700;

    /* background-color: red; */
  }

  .contactContentWrapOne {
    width: 100%;
    /* background-color: red; */
  }

  .contactWrapperBtom {
    width: 100%;

    flex-direction: column;
  }

  .formAndCategoryWrap {
    width: 100%;
  }

  .contactUSImg {
    width: 50%;
  }

  .contactUsSubmit {
    padding: 1.5em;

    border: 0px solid #e4e7ec;
    border-radius: 2px;
  }
}

@media (max-width: 500px) {
  .contactUsFloatingContent {
    width: 90%;

    margin-inline: auto;

    display: flex;
    flex-direction: column;

    align-items: flex-start;
    justify-content: center;

    gap: 1em;

    background-color: #fff;
    border-radius: 20px;

    padding: 2em 2em;

    position: relative;
    top: -12em;

    box-shadow: 0px 0px 15px -5px #c2c2c2;
  }

  .topic {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.7em, 0.95vw, 0.95rem);
    font-weight: 600;

    padding: 0.6em 1em;

    border: 1px solid #dbdbdb;
    border-radius: 60px;
  }
}

.blog {
  width: 100%;

  margin-inline: auto;
}

.innerBlog {
  width: 90%;

  margin-inline: auto;
}

.blogImageSlider {
  width: 100%;
  height: 65vh;
  min-height: 400px;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: flex-start;

  margin-top: 9em;
  border-radius: 25px;

  position: relative;

  overflow-x: hidden;
}

.blogImageSlider::after {
  content: " ";

  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;

  /* z-index: 1; */

  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 96.99%),
    rgba(211, 211, 211, 0) 50%;

  border-radius: 25px;
}

.blogImageSlide {
  width: 100%;
  height: 100%;

  flex-shrink: 0;

  /* box-shadow: 0px 1px 3px 0px rgba(34, 34, 34, 0.25);
background-image: url("./assets/bg3.jpg"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  border-radius: 25px;

  transition: 750ms all ease-in-out;
}

.blogIndicator {
  width: 100%;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  gap: 0.5em;

  margin-top: 1em;

  /* background-color: red; */
}

.dots {
  width: 5px;
  height: 5px;
  /* aspect-ratio: 1/1; */

  background-color: #000;
  border-radius: 100%;

  opacity: 0.4;
  cursor: pointer;

  transition: 750ms all ease-in-out;
}

.blogSlideText {
  z-index: 1;
  width: 100%;

  color: #fff;

  position: absolute;
  bottom: 15px;
  left: 0;

  /* background-color: red; */

  padding: 0 3em;
}

.blogSlideHeadText {
  width: 90%;
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.4rem, 3vw, 3rem);
  font-weight: 500;
}

.blogSlideSubText {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.8rem, 1vw, 1rem);
  font-weight: 300;

  margin-top: 1em;
  margin-bottom: 2em;
}

.blogPostsWrap {
  width: 95%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 0em;

  margin-top: 3em;
  margin-bottom: 8em;

  margin-inline: auto;
}

.filterText {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.85rem, 1vw, 1rem);
  font-weight: 400;

  padding: 1em;

  color: #222222;
  background-color: #e3d9fe;
}

.blogPosts {
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: center;
  justify-content: center;

  gap: 2em;
}

.blogPosts > * {
  /* flex-basis: 30%; */
  flex-grow: 1;
}

.blogPost {
  width: 29%;
  min-width: 250px;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 1em;

  /* background-color: red; */

  margin-top: 2em;
}

.blogPostImage {
  width: 100%;
  height: 30vh;
  min-height: 230px;

  object-fit: cover;

  border-radius: 20px;
}

.blogText {
  width: 100%;

  font-family: "Space Grotesk", sans-serif;

  margin-top: 0.5em;
}

.blogHeadText {
  font-size: clamp(1.2rem, 1.4vw, 1.4rem);
  font-weight: 600;
}

.blogSubText {
  font-size: clamp(0.85rem, 1vw, 1rem);
  font-weight: 400;

  margin-top: 0.5em;
}

.blogReadMore {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.85rem, 1vw, 1rem);
  font-weight: 500;

  color: #794dc2;
  cursor: pointer;

  transition: 0.5s;

  padding-top: 2em;
}

.blogReadMore:hover {
  color: rgb(60, 41, 92);
}

.allWrapper {
  width: 100%;
  max-width: 1600px;

  margin-inline: auto;

  /* background-color: red; */

  /* display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center */
}
